import React from 'react';
import { components } from 'react-select';

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }: any) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child, i) => (
        <React.Fragment key={i}>
          {child && child.type !== Placeholder ? child : null}
        </React.Fragment>
      ))}
    </ValueContainer>
  );
};

export default CustomValueContainer;
