export enum ROUTES {
  HOME = '/',
  SEARCH = '/search',
  TAG = '/tag/:tag',
  BATCHES = '/batch',
  BATCH_SINGLE = '/batch/:batch',
  LOCATION = '/location',
  SINGLE_LOCATION = '/location/:locationId',
  CATEGORIES = '/categories',
  EVENTS = '/events',
  ERROR = '*',
}
