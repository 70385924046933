import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomSelect from 'src/components/CustomSelect';
import { IEvent } from 'src/store/services/eventsApi/eventsTypes';
import { IOptions, ISelectOptions } from 'src/types';
import { getTypeOptions } from 'src/constants/typeOptions';
import { getActionOptions } from 'src/constants/actionOptions';
import SearchField from '../../../../../components/SearchField';
import { ServerError } from '../../../../../store/storeTypes';
import ServerFormError from '../../../../../components/ServerFormError';

interface IEventsFilter {
  events: IEvent[];
  id: string;
  setId: React.Dispatch<React.SetStateAction<string>>;
  type: string;
  setType: React.Dispatch<React.SetStateAction<string>>;
  action: string;
  setAction: React.Dispatch<React.SetStateAction<string>>;
  isError: boolean;
  error?: ServerError;
}

const EventsFilter = ({
  id,
  setId,
  type,
  setType,
  action,
  setAction,
  isError,
  error,
}: IEventsFilter) => {
  const { t, i18n } = useTranslation('events');
  const [typeOptions, setTypeOptions] = useState<IOptions[]>([]);
  const [actionOptions, setActionOptions] = useState<IOptions[]>([]);
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => setId(event.target.value);

  useEffect(() => {
    const loadSelectOptions = async () => {
      const typeOptions = await getTypeOptions();
      const actionOptions = await getActionOptions();
      setActionOptions(actionOptions);
      setTypeOptions(typeOptions);
    };

    loadSelectOptions();

    const handleLanguageChange = () => {
      loadSelectOptions();
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [t, i18n]);

  const typeValue = useMemo(
    () => typeOptions.find(({ value }) => value === type),
    [type, typeOptions],
  );
  const actionValue = useMemo(
    () => actionOptions.find(({ value }) => value === action),
    [action, actionOptions],
  );

  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row items-end justify-center md:justify-between w-full">
        <SearchField
          placeholder={t('input-search-by-id-placeholder')}
          type="number"
          value={id}
          onChange={handleSearch}
          wrapperClassName="w-full md:max-w-[30%] !mb-4 lg:!mb-0"
        />
        <CustomSelect
          placeholder={t('input-search-by-type-placeholder')}
          value={typeValue}
          options={typeOptions}
          onSelect={(option: ISelectOptions) => setType(option?.value)}
          withErrorField={false}
          customStyle="w-full md:w-1/3 mb-4 lg:mb-0"
        />
        <CustomSelect
          placeholder={t('input-search-by-action-placeholder')}
          value={actionValue}
          options={actionOptions}
          onSelect={(option: ISelectOptions) => setAction(option?.value)}
          withErrorField={false}
          customStyle="w-full md:w-1/3"
        />
      </div>
      <ServerFormError
        title={'Bad request:'}
        requestError={error as ServerError}
        isError={isError}
      />
    </div>
  );
};

export default EventsFilter;
