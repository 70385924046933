import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IEvent } from 'src/store/services/eventsApi/eventsTypes';

interface IEventRow {
  event: IEvent;
  classNames?: string;
}

const EventRow = ({ event, classNames }: IEventRow) => {
  const { t } = useTranslation('events');
  const { id, type, action, timestamp } = event;
  const isAsset = type === 'ASSET';
  const history = useHistory();
  const handleOpenSingleEvent = useCallback(() => {
    history.push(`/${isAsset ? 'tag' : type.toLowerCase()}/${id}`);
  }, [id, history, type, isAsset]);

  return (
    <tr
      onClick={handleOpenSingleEvent}
      className={`cursor-pointer even:bg-[#f9f9f9] ${classNames}`}
    >
      <td className="border border-solid border-[#ddd] text-base p-[6px] text-left relative">
        {t(`select-${type.toLowerCase()}-option`)}
      </td>
      <td className="border border-solid border-[#ddd] text-[12px] text-[#555] p-[6px] text-left relative">
        {t(`select-${action.toLowerCase()}-option`)}
      </td>
      <td className="border border-solid border-[#ddd] text-[12px] text-[#555] p-[6px] text-left relative">
        {timestamp}
      </td>
    </tr>
  );
};

export default EventRow;
