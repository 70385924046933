import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyGetAssetQuery } from '../../../../../../store/services/assetsApi';
import { IMultiSelectOptions } from '../../../../../../types';
import { handleChangeMultiSelect } from '../../../../../../helpers/handleChangeFormik/handleChangeMultiSelect';
import CustomSelect from '../../../../../CustomSelect';
import ScanButton from '../../../../../../views/Home/components/ScanButton';
import useDebounce from '../../../../../../hooks/useDebounce';
import { useLazyGetTagQuery } from '../../../../../../store/services/tagsApi';
import { ITagData } from '../../../../../../store/services/tagsApi/tagTypes';
import { children } from 'hastscript/lib/jsx-classic';

interface IBatchTagsMultiselect {
  setFieldValue: any;
}

const BatchTagsMultiselect = ({ setFieldValue }: IBatchTagsMultiselect) => {
  const [triggerAssetSearch, { data: filteredAssetData, isSuccess: isFilteredTagDataSuccess }] =
    useLazyGetAssetQuery();
  const [triggerAssetSearchByTag, { data: assetTagData, isSuccess: isAssetSearchByTagSuccess }] =
    useLazyGetTagQuery();
  const [selectedAssets, setSelectedAssets] = useState<IMultiSelectOptions | []>([]);
  const [isScannerOpen, setIsScannerOpen] = useState<boolean>(false);
  const [debouncedSelectInputValue, selectInputValue, setDebouncedSelectInputValue] = useDebounce(
    '',
    800,
  );
  const [currentTagData, setCurrentTagData] = useState<ITagData | undefined>(undefined);
  const tagRegExp = useMemo(() => /^.{3}-.{3}$/, []);

  useEffect(() => {
    tagRegExp.test(debouncedSelectInputValue)
      ? triggerAssetSearchByTag({ tag: debouncedSelectInputValue }).then(({ data }) =>
          setCurrentTagData(data),
        )
      : triggerAssetSearch({
          ...(debouncedSelectInputValue ? { search: debouncedSelectInputValue } : {}),
          pageSize: 10,
        }).then(() => setCurrentTagData(undefined));
  }, [debouncedSelectInputValue, triggerAssetSearch, triggerAssetSearchByTag, tagRegExp]);

  const filteredTagOptions = useMemo(() => {
    return !currentTagData && filteredAssetData
      ? filteredAssetData.assets.map((tag) => {
          return { label: tag.name, value: tag.id };
        })
      : currentTagData
        ? [{ label: currentTagData.name, value: currentTagData.id }]
        : [];
  }, [filteredAssetData, currentTagData]);

  const handleScannerToggle = useCallback(() => setIsScannerOpen((prevState) => !prevState), []);

  const handleSelectChange = useCallback(
    (options: IMultiSelectOptions) => {
      handleChangeMultiSelect({ option: options, field: 'assetIds', setFieldValue });
      setSelectedAssets(options);
      setTimeout(() => {
        const element = document.getElementById('horizontal-scroll-container');
        element?.children[element?.children?.length - 1]?.scrollIntoView();
      });
    },
    [setFieldValue],
  );

  const handleSelectInputChange = useCallback(
    (inputValue: string) => {
      setDebouncedSelectInputValue(inputValue);
    },
    [setDebouncedSelectInputValue],
  );

  return (
    <div className="flex items-center gap-3">
      <ScanButton
        isOpen={isScannerOpen}
        handleScannerOpen={handleScannerToggle}
        handleScannerClose={handleScannerToggle}
        scannerMode="assetMultiselect"
        selectedAssets={selectedAssets}
        setFieldValue={handleSelectChange}
      />
      <CustomSelect
        placeholder="Select tags"
        value={selectedAssets}
        options={filteredTagOptions}
        isMulti
        isSearchable
        onSelect={handleSelectChange}
        onInputChange={handleSelectInputChange}
        shouldDisableFilter={!!currentTagData}
        hasHorizontalScroll
        customStyle="w-full"
      />
    </div>
  );
};

export default BatchTagsMultiselect;
