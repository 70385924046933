import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { IBatchData } from '../../../../../store/services/batchApi/batchTypes';

interface IBatchRow {
  data: IBatchData;
}

const BatchRow = ({ data }: IBatchRow) => {
  const { id, name, description, type, state } = data;
  const history = useHistory();

  const handleOpenSingleBatch = useCallback(() => {
    history.push(`/batch/${id}`);
  }, [id, history]);

  const activeStateStyle = state === 'IN_PROGRESS' ? { borderTop: '3px dashed #CFD24B' } : {};
  const completedStateStyle = state === 'COMPLETED' ? { borderTop: '3px solid #4C443C' } : {};
  const plannedStateStyle = state === 'PLANNED' ? { borderTop: '3px dashed #5F9548' } : {};

  return (
    <tr
      onClick={handleOpenSingleBatch}
      className="font-['Mukta_Mahee',_Arial,_sans-serif] cursor-pointer even:bg-[#f9f9f9]"
    >
      <td className="border border-solid border-[#ddd] text-base p-[6px] text-left relative">
        {name}
      </td>
      <td className="border border-solid border-[#ddd] text-[12px] text-[#555] p-[6px] text-left relative">
        {description}
      </td>
      <td className="border border-solid border-[#ddd] text-[10px] text-[#555] p-[6px] text-left relative">
        {type}
      </td>
      <td
        className="border border-solid border-[#ddd] text-[10px] text-[#555] p-[6px] text-left relative"
        style={
          state === 'COMPLETED'
            ? completedStateStyle
            : state === 'IN_PROGRESS'
              ? activeStateStyle
              : plannedStateStyle
        }
      >
        {state}
      </td>
    </tr>
  );
};

export default BatchRow;
