import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEditLocationMutation } from 'src/store/services/locationApi';
import LocationForm from './components/LocationForm';
import { ILocationData } from 'src/store/services/locationApi/locationTypes';

interface IEditLocationModal {
  onClose: () => void;
  isOpened: boolean;
  data?: ILocationData;
}

const EditLocationModal = ({ data, isOpened, onClose }: IEditLocationModal) => {
  const { t } = useTranslation('location');
  const [editLocation, { isError, isLoading, error, isSuccess }] = useEditLocationMutation();

  return (
    <LocationForm
      title={t('edit-location')}
      onSubmit={editLocation}
      isSuccess={isSuccess}
      isLoading={isLoading}
      isError={isError}
      error={error}
      data={data}
      isOpened={isOpened}
      onClose={onClose}
    />
  );
};

export default EditLocationModal;
