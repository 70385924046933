import { BaseQueryMeta } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { REQUEST } from 'src/store/storeTypes';
import { apiRtk } from '../';
import { ICategories } from './categoryTypes';

export const categoryApi = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getCategories: build.query({
      query: () => ({
        url: `${process.env.REACT_APP_CATEGORY_SERVER_URL}/category`,
        method: REQUEST.GET,
      }),
      transformResponse: (response: ICategories) => response,
      transformErrorResponse: (error: any, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
    }),
  }),
});

export const { useGetCategoriesQuery } = categoryApi;
