import React from 'react';

interface IProccessItemProps {
  icon: React.FunctionComponent<any>;
  text: string;
  onClick: () => void;
}

const ProcessItem = ({ icon: Icon, text, onClick }: IProccessItemProps) => (
  <li onClick={onClick} className="flex gap-[10px] cursor-pointer p-1">
    <Icon className="w-6 h-6 fill-[#4C443C]" />
    <p className="font-['Mukta_Mahee',_Arial,_sans-serif]">{text}</p>
  </li>
);

export default ProcessItem;
