import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateLocationMutation } from 'src/store/services/locationApi';
import LocationForm from './components/LocationForm';

interface IEditLocationModal {
  onClose: () => void;
  isOpened: boolean;
}

const AddLocationModal = ({ isOpened, onClose }: IEditLocationModal) => {
  const { t } = useTranslation('location');
  const [createLocation, { isSuccess, isLoading, isError, error }] = useCreateLocationMutation();
  return (
    <LocationForm
      title={t('create-location')}
      onSubmit={createLocation}
      isSuccess={isSuccess}
      isLoading={isLoading}
      isError={isError}
      error={error}
      isOpened={isOpened}
      onClose={onClose}
    />
  );
};

export default AddLocationModal;
