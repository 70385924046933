import React, { useCallback, useEffect, useState } from 'react';
import { useGetBatchesQuery } from '../../store/services/batchApi';
import Loading from '../../components/Loading';
import { IBatchData } from '../../store/services/batchApi/batchTypes';
import ScanButton from '../Home/components/ScanButton';
import ContextMenuModal from '../../components/Modals/ContextMenuModal';
import BatchesFilter from './components/BatchesFilter';
import BatchesTable from './components/BatchesTable';

const Batches = () => {
  const [selectedStates, setSelectedStates] = useState<[] | string[]>(['PLANNED', 'IN_PROGRESS']);
  const { data, isLoading } = useGetBatchesQuery(
    selectedStates.length
      ? {
          states: selectedStates.join(),
        }
      : {},
  );
  const [filteredBatches, setFilteredBatches] = useState<IBatchData[] | undefined>(undefined);

  useEffect(() => {
    if (!isLoading) setFilteredBatches(data);
  }, [isLoading, data]);

  const [isScannerOpened, setIsScannerOpened] = useState<boolean>(false);
  const handleOpenScanner = () => setIsScannerOpened(true);
  const handleCloseScanner = () => setIsScannerOpened(false);

  const handleScanFilter = useCallback((data: IBatchData[]) => {
    setFilteredBatches(data);
    handleCloseScanner();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <ScanButton
          isOpen={isScannerOpened}
          handleScannerOpen={handleOpenScanner}
          handleScannerClose={handleCloseScanner}
          scannerMode="batch"
          setFieldValue={handleScanFilter}
        />
        <BatchesFilter
          batches={data}
          setBatches={setFilteredBatches}
          setSelectedStates={setSelectedStates}
        />
      </div>
      {filteredBatches && <BatchesTable data={filteredBatches} />}
      <ContextMenuModal />
    </>
  );
};

export default Batches;
