import { useFormik } from 'formik';
import { useGetPdfFormatsQuery } from '../../../store/services/configApi';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import CustomSelect from '../../CustomSelect';
import { ISelectOptions } from '../../../types';
import Loading from '../../Loading';

interface ISelectPDFFormat {
  setSelectedPDFFormat: Dispatch<SetStateAction<string | null>>;
}

const SelectPDFFormat = ({ setSelectedPDFFormat }: ISelectPDFFormat) => {
  const { data: pdfFormatsData, isLoading: isPdfFormatsDataLoading } = useGetPdfFormatsQuery({});
  const pdfFormatsOptions = pdfFormatsData?.length
    ? pdfFormatsData.map((pdfFormat) => {
        return { label: pdfFormat, value: pdfFormat };
      })
    : [];

  useEffect(() => {
    return () => setSelectedPDFFormat(null);
  }, [setSelectedPDFFormat]);

  const { values, setFieldValue } = useFormik({
    initialValues: {
      format: { label: '--', value: null },
    },
    onSubmit: () => {},
  });


  const handleSelectFormat = useCallback(
    (option: ISelectOptions) => {
      setFieldValue('format', option);
      setSelectedPDFFormat(option?.value);
    },
    [setSelectedPDFFormat, setFieldValue],
  );

  return !isPdfFormatsDataLoading ? (
    <div className="w-full">
      <CustomSelect
        placeholder="Choose PDF format"
        value={values.format}
        onSelect={handleSelectFormat}
        options={pdfFormatsOptions}
      />
    </div>
  ) : (
    <Loading />
  );
};

export default SelectPDFFormat;
