import { useEffect, useState } from "react";

const SUPPORTED_LANGUAGES = ['en', 'no'];

const mapLanguageToSupported = (lang: string) => {
    const baseLang = lang.split('-')[0]; 
    if (baseLang === 'nb' || baseLang === 'nn') {
        return 'no'; 
    }
    if (baseLang === 'en') {
        return 'en'; 
    }
    return SUPPORTED_LANGUAGES.includes(baseLang) ? baseLang : 'en'; 
};

const useFetchUserLanguage = () => {
    const [language, setLanguage] = useState<string>('en');
    const [loading, setLoading] = useState<boolean>(true); 

    useEffect(() => {
        const fetchLanguage = () => {
            const browserLanguages = navigator.languages || ['en'];

            const matchedLanguage = browserLanguages
                .map(mapLanguageToSupported)
                .find((lang) => SUPPORTED_LANGUAGES.includes(lang));

            setLanguage(matchedLanguage || 'en'); 
            setLoading(false);
        };

        fetchLanguage();
    }, []);

    return { language, loading };
};

export default useFetchUserLanguage;
