import { useLazyGetAssetFileByBatchIdQuery } from '../../../store/services/assetsApi';
import useDownloadFile from '../../../hooks/useDownloadFile';
import { useState } from 'react';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import SelectPDFFormat from './SelectPDFFormat';
import Loading from '../../Loading';

interface IDownloadBatchProps {
  batchId: string;
}

const DownloadBatch = ({ batchId }: IDownloadBatchProps) => {
  const [isPDFFormatSelectionModalOpen, setIsPDFFormatSelectionModalOpen] =
    useState<boolean>(false);
  const [selectedPDFFormat, setSelectedPDFFormat] = useState<string | null>(null);

  const [
    triggerBatchPDF,
    { data: pdfBatchData, isLoading: isPdfDataLoading, isFetching: isPdfFetching },
  ] = useLazyGetAssetFileByBatchIdQuery();

  const [
    triggerBatchCSV,
    { data: csvBatchData, isLoading: isCsvDataLoading, isFetching: isCsvFetching },
  ] = useLazyGetAssetFileByBatchIdQuery();

  const downloadPdfRef = useDownloadFile({
    fileData: pdfBatchData,
    isFileLoading: isPdfDataLoading,
    fileId: batchId,
    fileType: 'pdf',
  });

  const downloadCsvRef = useDownloadFile({
    fileData: csvBatchData,
    isFileLoading: isCsvDataLoading,
    fileId: batchId,
    fileType: 'csv',
  });

  const handleClickLoadPDF = () => {
    if (!downloadPdfRef?.current?.href) {
      triggerBatchPDF({
        id: batchId,
        headers: { Accept: 'application/pdf' },
        params: selectedPDFFormat ? { format: selectedPDFFormat } : null,
      });
    }
  };
  const handleClickLoadCSV = () => {
    if (!downloadCsvRef?.current?.href) {
      triggerBatchCSV({
        id: batchId,
        headers: { Accept: 'text/csv' },
      });
    }
  };

  const handleTogglePDFFormatSelectionModal = () => {
    setIsPDFFormatSelectionModalOpen((prevState) => !prevState);
  };

  if (isPdfFetching || isCsvFetching) {
    return <Loading />;
  }

  return (
    <>
      <a
        className="cursor-pointer"
        onClick={handleTogglePDFFormatSelectionModal}
        ref={downloadPdfRef}
      >
        PDF
      </a>
      <span>|</span>
      <a className="cursor-pointer" onClick={handleClickLoadCSV} ref={downloadCsvRef}>
        CSV
      </a>
      <ConfirmationModal
        isOpen={isPDFFormatSelectionModalOpen}
        onAccept={handleClickLoadPDF}
        onCancel={handleTogglePDFFormatSelectionModal}
        onClose={handleTogglePDFFormatSelectionModal}
        confirmationText="Choose PDF format"
        confirmationAcceptText="Download PDF"
        confirmationCancelText="Cancel"
      >
        <SelectPDFFormat setSelectedPDFFormat={setSelectedPDFFormat} />
      </ConfirmationModal>
    </>
  );
};

export default DownloadBatch;
