interface ICategoriesPropertiesBadge {
  categoryProperties: Record<string, string>;
}

const CategoriesPropertiesBadge = ({ categoryProperties }: ICategoriesPropertiesBadge) => {
  const categoryPropertiesList = Object.entries(categoryProperties || {});
  const filteredProperties = categoryPropertiesList.filter(
    ([propertyName]) => propertyName !== 'inventoryType',
  );

  return filteredProperties.length ? (
    <div className="mt-1">
      {filteredProperties.map(([propertyName, propertyValue]) => (
        <div
          key={propertyName}
          className="py-1 px-2 my-2 mx-0.5 inline-block bg-tag transition-colors duration-300 ease-in-out text-xs text-white text-center rounded-xl"
        >
          {propertyName}: <span className="text-load-button">{propertyValue}</span>
        </div>
      ))}
    </div>
  ) : null;
};

export default CategoriesPropertiesBadge;
