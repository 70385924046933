import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { IDocument } from '../../../../../../../../../store/services/tagsApi/tagTypes';
import ModalContainer from '../../../../../../../../../components/Modals/components/ModalContainer';
import ItemImage from '../../../../../../../../../components/ItemImage';
import SwiperNavigation from './SwiperNavigation';
import 'swiper/css/navigation';
import 'swiper/css';

interface ISwiperGallery {
  slides: IDocument[];
}

const SwiperGallery = ({ slides }: ISwiperGallery) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number | null>(null);
  const isSmallScreen = windowWidth < 768;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSlideClick = (index: number) => {
    setActiveSlideIndex(index);
    toggleModal();
  };

  const toggleModal = () => {
    setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
  };

  return (
    <div className="relative w-full h-full scale-[1]">
      <div className="bg-transparent rounded-lg overflow-hidden h-[150px]">
        <Swiper
          slidesPerView={isSmallScreen ? 2 : 4}
          watchOverflow={true}
          direction="horizontal"
          spaceBetween={60}
          modules={[Navigation]}
          className="relative m-0 w-full h-full"
        >
          {slides.map((slide, index) => (
            <SwiperSlide
              key={`${slide.url}-${index}`}
              className="cursor-pointer"
              onClick={() => handleSlideClick(index)}
            >
              <ItemImage
                image={slide.url}
                alt={`slide-${index}`}
                customClassName="!object-cover h-full w-full"
              />
            </SwiperSlide>
          ))}
          {slides.length > 2 && <SwiperNavigation />}
        </Swiper>
      </div>

      {isModalOpen && activeSlideIndex !== null && (
        <ModalContainer isModalOpened={isModalOpen} onClose={toggleModal}>
          <div className="flex justify-center items-center w-full h-full lg:px-14">
            <img
              src={slides[activeSlideIndex].url}
              alt="current slide"
              className="w-full md:max-w-[85%] h-[40%] md:h-[75%] object-contain"
            />
          </div>
        </ModalContainer>
      )}
    </div>
  );
};

export default SwiperGallery;
