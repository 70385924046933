import { useState } from 'react';
import CategoryRow from './components/CategoryRow';
import CustomAccordion from '../../../../../../../../components/CustomAccordion';

interface ICategoriesTable {
  category: string;
  categoryProperties: Record<string, string>;
  rowClassNames?: string;
}

const CategoriesTable = ({ category, categoryProperties, rowClassNames }: ICategoriesTable) => {
  const [isSelectedH05AccordionOpen, setIsSelectedH05AccordionOpen] = useState<boolean>(
      window.innerWidth >= 768,
  );
  const categoryPropertiesList = Object.entries(categoryProperties || {});
  const filteredProperties = categoryPropertiesList.filter(
    ([propertyName]) => propertyName !== 'inventoryType',
  );

  return filteredProperties.length ? (
    <CustomAccordion
      isAccordionOpen={isSelectedH05AccordionOpen}
      setIsAccordionOpen={setIsSelectedH05AccordionOpen}
      title={category}
      accordionWrapperClassName="w-full mt-0 font-tag-font-family text-tag-font-color"
    >
      <table className="w-full border-collapse text-sm leading-normal !mt-5 mb-4">
        <tbody>
          {filteredProperties.map(([propertyName, propertyValue]) => (
            <CategoryRow
              key={propertyName}
              propertyName={propertyName}
              propertyValue={propertyValue}
              classNames={rowClassNames}
            />
          ))}
        </tbody>
      </table>
    </CustomAccordion>
  ) : (
    <></>
  );
};

export default CategoriesTable;
