import React from 'react';
import { ILocation } from '../../../../../../store/services/locationApi/locationTypes';
import { IBatchData } from '../../../../../../store/services/batchApi/batchTypes';
import BatchesBadge from '../BatchesBadge';
import LocalesBadge from '../LocationBadge';

interface IAuthenticatedTagInfoProps {
  location?: ILocation;
  batches?: IBatchData[];
  isAuthenticated: boolean;
}

const AuthenticatedTagInfo = ({
  location,
  batches,
  isAuthenticated,
}: IAuthenticatedTagInfoProps) => {
  return isAuthenticated ? (
    <div className="flex flex-col justify-between">
      <LocalesBadge local={location || null} />
      <BatchesBadge batches={batches || []} />
    </div>
  ) : null;
};

export default React.memo(AuthenticatedTagInfo);
