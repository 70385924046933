import kinnarpsLogo from 'src/assets/logo/kinnarps-logo.png';
import hagLogo from 'src/assets/logo/hag-logo.png';

export const assetCompanyThemeConfigs: Record<string, Record<string, string>> = {
  defaultTheme: {
    '--category-font-family': "'Mukta Mahee', 'sans-serif'",
    '--category-font-color': '76 68 60',
    '--category-font-size': '1.5em',
    '--background-image': ``,
    '--background-position': '95% 12%',
  },
  Håg: {
    '--category-font-color': '100 100 100',
    '--category-font-family': 'serif',
    '--category-font-size': '1.5em',
    '--background-position': '95% 2%',
    '--background-image': `url(${hagLogo})`,
  },
  Kinnarps: {
    '--category-font-color': '100 100 100',
    '--category-font-size': '1.5em',
    '--category-font-family': "'Roboto', 'sans-serif'",
    '--background-position': '95% 2%',
    '--background-image': `url(${kinnarpsLogo})`,
  },
};
