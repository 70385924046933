import imageWarranty from 'src/assets/warranty.png';

interface ITagWarranty {
  expirationDate: string;
}

const TagWarranty = ({ expirationDate }: ITagWarranty) => {
  return (
    <div className="flex justify-center items-center flex-col gap-3 w-1/2">
      <img src={imageWarranty} alt="warranty" className="w-[100px] h-[100px]" />
      <p className="font-mukta-mahee text-grey-asset">{expirationDate}</p>
    </div>
  );
};

export default TagWarranty;
