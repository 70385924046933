import { useEffect } from 'react';
import { themeConfigs } from '../constants/themeConfigs';

const useChangeTheme = () => {
  useEffect(() => {
    const theme = process.env.REACT_APP_THEME;

    const sortVariables = (themeConfigs: { [key: string]: string }) => {
      const bodyElement = document.getElementsByTagName('body')[0];

      if (bodyElement) {
        Object.entries(themeConfigs).forEach(([key, value]) => {
          bodyElement.style.setProperty(key, value);
        });
      }
    };

    const selectedThemeConfigs = theme ? themeConfigs[theme] : themeConfigs.defaultTheme;
    sortVariables(selectedThemeConfigs);

    return () => {
      sortVariables(themeConfigs.defaultTheme);
    };
  }, []);
};

export default useChangeTheme;
