import React from 'react';
import { useHorizontalScroll } from '../../hooks/useHorizontalScroll';
import { components } from 'react-select';

const { ValueContainer, Placeholder } = components;

const HorizontalScrollValueContainer = ({ children, ...props }: any) => {
  const scrollRef: any = useHorizontalScroll();
  return (
    <ValueContainer {...props}>
      <div
        id="horizontal-scroll-container"
        className="flex overflow-x-scroll flex-nowrap"
        style={{ scrollbarWidth: 'none' }}
        ref={window.innerWidth < 768 ? null : scrollRef}
      >
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child, i) => (
          <React.Fragment key={i}>
            {child && child.type !== Placeholder ? child : null}
          </React.Fragment>
        ))}
      </div>
    </ValueContainer>
  );
};

export default HorizontalScrollValueContainer;
