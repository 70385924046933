import React, { useState } from 'react';
import { useGetBatchesQuery } from '../../../store/services/batchApi';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import CustomAccordion from '../../../components/CustomAccordion';

interface ISearchBatches {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseAccordions: () => void;
}

const SearchBatches = ({ isOpen, setIsOpen, handleCloseAccordions }: ISearchBatches) => {
  const { t } = useTranslation('search');
  const { data } = useGetBatchesQuery({});
  const history = useHistory();
  const [showAll, setShowAll] = useState(false);

  const maxInitialDisplay = 9;

  const visibleBatches = data?.slice(0, maxInitialDisplay) || [];
  const hiddenBatches = data?.slice(maxInitialDisplay) || [];

  const handleBatchClick = (batchId: number) => {
    history.push(`/search?searchType=batchId&searchValue=${batchId}`);
    handleCloseAccordions();
  };

  const handleShowAllClick = () => {
    setShowAll(true);
  };

  return (
    <CustomAccordion
      title={t('title-batches')}
      isAccordionOpen={isOpen}
      setIsAccordionOpen={setIsOpen}
    >
      {!!data?.length && (
        <div>
          {visibleBatches.map(({ id, name }) => (
            <p
              key={id}
              onClick={() => handleBatchClick(id)}
              className="inline-block py-1 px-2 my-2 mx-0.5 bg-batch-badge transition-colors duration-300 ease-in-out text-xs text-batch-badge-foreground-color text-center rounded-xl cursor-pointer"
            >
              {name}
            </p>
          ))}
          {hiddenBatches?.length > 0 && !showAll && (
            <p
              className="inline-block py-1 px-2 my-2 mx-0.5 bg-batch-badge transition-colors duration-300 ease-in-out text-xs text-white text-center rounded-xl cursor-pointer"
              onClick={handleShowAllClick}
            >
              {t('search-batch-show-more-btn')} +
            </p>
          )}
          {showAll &&
            hiddenBatches.map(({ id, name }) => (
              <p
                key={id}
                onClick={() => handleBatchClick(id)}
                className="inline-block py-1 px-2 my-2 mx-0.5 bg-batch-badge transition-colors duration-300 ease-in-out text-xs text-batch-badge-foreground-color text-center rounded-xl cursor-pointer"
              >
                {name}
              </p>
            ))}
        </div>
      )}
    </CustomAccordion>
  );
};

export default React.memo(SearchBatches);
