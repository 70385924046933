import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEditBatchAssetsMutation, useEditBatchMutation } from 'src/store/services/batchApi';
import { PatchBatchBodyData } from 'src/store/services/batchApi/batchTypes';
import BatchForm, { IBatchEditMode } from './components/BatchForm';

interface IEditBatchModal {
  onClose: () => void;
  isOpened: boolean;
  data?: PatchBatchBodyData;
  editMode?: IBatchEditMode;
}

const EditBatchModal = ({ data, isOpened, onClose, editMode = 'default' }: IEditBatchModal) => {
  const { t } = useTranslation('batches');
  const [
    editBatchAssets,
    {
      isSuccess: isSuccessBatchAsset,
      isLoading: isLoadingBatchAsset,
      isError: isErrorBatchAssets,
      error: errorBatchAssets,
    },
  ] = useEditBatchAssetsMutation({});
  const [editBatch, { isSuccess, isLoading, isError, error }] = useEditBatchMutation({});

  return (
    <BatchForm
      editMode={editMode}
      title={editMode === 'default' ? t('edit-batch') : t('edit-batch-assets')}
      onSubmit={editMode === 'default' ? editBatch : editBatchAssets}
      isSuccess={editMode === 'default' ? isSuccess : isSuccessBatchAsset}
      isLoading={editMode === 'default' ? isLoading : isLoadingBatchAsset}
      isError={editMode === 'default' ? isError : isErrorBatchAssets}
      error={editMode === 'default' ? error : errorBatchAssets}
      data={data}
      isOpened={isOpened}
      onClose={onClose}
    />
  );
};

export default EditBatchModal;
