import React, { ReactNode } from 'react';
import { Modal } from 'flowbite-react';
import { ReactComponent as IconClose } from '../../../assets/IconClose.svg';

interface IModalContainerProps {
  isModalOpened: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
}

const ModalContainer = ({ children, isModalOpened, onClose, className }: IModalContainerProps) => {
  return (
    <Modal
      show={isModalOpened}
      onClose={onClose}
      className={`pb-[50px]  bg-inherit !h-[100%] ${className}`}
    >
      {children}
      <button
        type="button"
        className="absolute right-6 top-6 rounded-full bg-grey-asset"
        onClick={onClose}
      >
        <IconClose className="w-[1.6rem] h-[1.6rem] fill-white" />
      </button>
    </Modal>
  );
};

export default ModalContainer;
