import React, { ReactNode } from 'react';

interface IFormWrapper {
  onSubmit: () => void;
  children: ReactNode;
}

const FormWrapper = ({ onSubmit, children }: IFormWrapper) => {
  return (
    <form onSubmit={onSubmit} className=" w-full overflow-y-auto  h-full ">
      <div className="flex items-center h-full">
        <div className="m-auto w-[350px] md:w-[650px] min-h-[300px]">{children}</div>
      </div>
    </form>
  );
};

export default FormWrapper;
