import SectionList from './SectionList';
import { useTranslation } from 'react-i18next';

const PrivacySection = () => {
  const { t } = useTranslation('landing');
  const privacyItems = [
    { link: 'https://www.sirktek.com/privacy', text: t('footer-item-policy') },
    { link: 'https://www.sirktek.com/termsofuse', text: t('footer-item-terms') },
    {
      link: 'https://www.sirktek.com/cookie',
      text: t('footer-item-cookie-policy'),
    },
  ];

  return (
    <div className="mb-[10px]">
      <h3 className="text-center text-[14.04px] font-bold pb-[5px]">{t('footer-title-privacy')}</h3>
      <SectionList items={privacyItems} />
    </div>
  );
};

export default PrivacySection;
