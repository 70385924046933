import { useTranslation } from 'react-i18next';

interface IImageProps {
  image?: string;
  alt?: string;
  customClassName?: string;
}

const ItemImage = ({ image, alt, customClassName }: IImageProps) => {
  const { t } = useTranslation('tag');

  return image ? (
    <img src={image} alt={alt} className={`block aspect-[1/1] w-full h-full ${customClassName}`} />
  ) : (
    <div className="flex justify-center items-center w-full h-full bg-primary-color">
      <p className="flex items-center text-center min-h-[100px] min-w-[100px] px-3">
        {t('take_photo')}
      </p>
    </div>
  );
};

export default ItemImage;
