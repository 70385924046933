import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { IAsset } from '../../../store/services/assetsApi/assetsTypes';
import AssetTagBadge from '../../AssetTagBadge';

interface IBatchAssetRow {
  asset: IAsset;
}

const AssetTableRow = ({ asset }: IBatchAssetRow) => {
  const history = useHistory();
  const handleOpenSingleAsset = useCallback(() => {
    history.push(`/tag/${asset.tag}`);
  }, [asset, history]);

  return (
    <tr onClick={handleOpenSingleAsset} className="cursor-pointer even:bg-[#f9f9f9]">
      <td className="p-[6px] w-1/2 text-base border border-solid border-[#ddd] text-left relative">
        {asset.name}
      </td>
      <td className="p-[6px] w-1/2 border border-solid border-[#ddd] text-left relative">
        <AssetTagBadge tag={asset.tag} />
      </td>
    </tr>
  );
};

export default AssetTableRow;
