import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEditAssetMutation } from 'src/store/services/assetsApi';
import { ITagData } from 'src/store/services/tagsApi/tagTypes';
import TagForm from './components/TagForm';

interface IEditTagModal {
  data?: ITagData;
  isOpened: boolean;
  onClose: () => void;
}

const EditTagModal = ({ onClose, isOpened, data }: IEditTagModal) => {
  const { t } = useTranslation('tag');
  const [editAsset, { isSuccess, isLoading, isError, error, reset }] = useEditAssetMutation({});

  return (
    <TagForm
      title={t('edit_tag')}
      onSubmit={editAsset}
      isSuccess={isSuccess}
      isLoading={isLoading}
      isError={isError}
      error={error}
      data={data}
      isOpened={isOpened}
      onClose={onClose}
      resetEditAsset={reset}
    />
  );
};

export default EditTagModal;
