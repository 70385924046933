import { BaseQueryMeta } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { ITagData } from 'src/store/services/tagsApi/tagTypes';
import { REQUEST } from 'src/store/storeTypes';
import { apiRtk, RTK_TAGS } from '../index';

export const tagsApi = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getTag: build.query({
      query: ({ tag, params, isTag = false }) => ({
        url: `/${tag.includes('-') || isTag ? 'tag' : 'asset'}/${tag}${params ? params : ''}`,
        method: REQUEST.GET,
      }),
      transformResponse: (response: ITagData) => response,
      transformErrorResponse: (error: any, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
      providesTags: () => [{ type: RTK_TAGS.TAG }],
    }),
    postAssetById: build.mutation({
      query: ({ assetId, body }: { assetId: number; body: FormData }) => ({
        url: `/asset/${assetId}`,
        method: REQUEST.POST,
        body,
      }),
      transformResponse: (response: any) => response,
      transformErrorResponse: (error: any, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
      invalidatesTags: () => [{ type: RTK_TAGS.TAG }],
    }),
  }),
});

export const { useGetTagQuery, useLazyGetTagQuery, usePostAssetByIdMutation } = tagsApi;
