import React, { ReactNode, useCallback, useState } from 'react';
import Select, { PropsValue } from 'react-select';
import { IOptions } from '../../types';
import ErrorField from '../ErrorField';
import CustomValueContainer from './CustomValueContainer';
import HorizontalScrollValueContainer from './HorizontalScrollValueContainer';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

interface ICustomSelect<T> extends StateManagerProps {
  options: IOptions[];
  onSelect: (option: T) => void;
  onChange?: () => void;
  onInputChange?: (value: string) => void;
  shouldDisableFilter?: boolean;
  customStyle?: string;
  withErrorField?: boolean;
  isError?: boolean;
  errorMessage?: any;
  isMulti?: boolean;
  placeholder?: string | ReactNode;
  isSearchable?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isChip?: boolean;
  disableFocus?: boolean;
  hasHorizontalScroll?: boolean;
  defaultValue?: PropsValue<IOptions> | undefined | null;
  value?: PropsValue<IOptions>;
}

const CustomSelect = <T,>({
  options,
  onSelect,
  onChange,
  shouldDisableFilter = false,
  customStyle,
  placeholder,
  isError,
  withErrorField = true,
  errorMessage,
  isMulti,
  isSearchable = false,
  isDisabled,
  isChip = false,
  disableFocus,
  hasHorizontalScroll = false,
  defaultValue,
  value,
  ...rest
}: ICustomSelect<T>) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleFocusChange = useCallback(() => {
    if (!disableFocus) setIsFocused((prevState) => !prevState);
  }, [disableFocus]);

  return (
    <div className={`${customStyle || ''}`}>
      <Select
        placeholder={placeholder}
        isMulti={isMulti}
        value={!defaultValue && value ? value : []}
        options={options}
        isSearchable={isSearchable}
        onChange={(option) => {
          onSelect(option as T);
          onChange?.();
        }}
        isDisabled={isDisabled}
        defaultValue={defaultValue || []}
        filterOption={shouldDisableFilter ? () => true : undefined}
        onFocus={handleFocusChange}
        onBlur={handleFocusChange}
        {...rest}
        components={{
          ValueContainer: hasHorizontalScroll
            ? HorizontalScrollValueContainer
            : CustomValueContainer,
        }}
        classNames={{
          input: () => '[&_input]:![box-shadow:none]',
        }}
        styles={{
          container: (provided, state) => ({
            ...provided,
            marginTop: 15,
          }),
          placeholder: (provided, state) => ({
            ...provided,
            position: 'absolute',
            top:
              state.hasValue || state.selectProps.inputValue || isFocused
                ? isMulti
                  ? -24
                  : -26
                : '0%',
            transition: 'top 0.1s, font-size 0.1s',
            fontSize:
              (isFocused || state.isFocused || state.hasValue || state.selectProps.inputValue) &&
              12,
            color: isChip ? 'white' : '',
          }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          input: (base, state) => ({
            ...base,
            ...(state.isMulti
              ? {
                  display:
                    state.options.length === 1 ||
                    state.options.length - (state.selectProps.value as any[]).length
                      ? 'inline-grid'
                      : 'none',
                }
              : {}),
          }),
          control: (base, state) => ({
            ...base,
            ...(hasHorizontalScroll
              ? { maxWidth: window.innerWidth >= 768 ? '568px' : '268px' }
              : {}),
            backgroundColor: isChip ? '#837d7d' : '',
            height: isChip ? '28px' : '',
          }),
          multiValue: (base, state) => ({
            ...base,
            minWidth: '100px',
          }),
          valueContainer: (base, state) => ({
            ...base,
            ...(hasHorizontalScroll ? { maxWidth: 'calc(100% - 104px)' } : {}),
          }),
          indicatorsContainer: (base, state) => ({
            ...base,
            display: isChip ? 'none' : 'flex',
          }),
          singleValue: (base) => ({
            ...base,
            color: isChip ? 'white' : '',
            textAlign: isChip ? 'center' : 'left',
          }),
        }}
        menuPortalTarget={document.body}
      />
      {withErrorField && <ErrorField errorMessage={errorMessage} />}
    </div>
  );
};

export default CustomSelect;
