import ModalContainer from '../components/ModalContainer';
import SerialAssetsForm from './components/SerialAssetsForm';

interface ISerialAssetsModal {
  isModalOpen: boolean;
  onModalClose: () => void;
}

const SerialAssetsModal = ({ isModalOpen, onModalClose }: ISerialAssetsModal) => {
  return (
    <ModalContainer isModalOpened={isModalOpen} onClose={onModalClose}>
      <SerialAssetsForm />
    </ModalContainer>
  );
};

export default SerialAssetsModal;
