import { IOptions } from 'src/types';
import i18n from 'i18next';

export const getTypeOptions = async (): Promise<IOptions[]> => {
  await i18n.loadNamespaces('events');
  return [
    { value: '', label: i18n.t('select-select-option', { ns: 'events' }) },
    { value: 'ASSET', label: i18n.t('select-asset-option', { ns: 'events' }) },
    { value: 'BATCH', label: i18n.t('select-batch-option', { ns: 'events' }) },
    { value: 'LOCATION', label: i18n.t('select-location-option', { ns: 'events' }) },
  ];
};

