import React, { useCallback, useRef } from 'react';
import { ReactComponent as IconLogout } from 'src/assets/footer-menu/IconLogout.svg';
import { useAuth0 } from '@auth0/auth0-react';
import useClickOutside from '../../../hooks/useClickOutSide/useClickOutside';

interface IUserMenu {
  onClose: () => void;
}

const UserMenu = ({ onClose }: IUserMenu) => {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const userMenuRef = useRef<HTMLDivElement>(null);

  const logoutWithRedirect = useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  const handleAuth = useCallback(() => {
    isAuthenticated ? logoutWithRedirect() : loginWithRedirect();
  }, [isAuthenticated, loginWithRedirect, logoutWithRedirect]);

  useClickOutside(userMenuRef, onClose);

  return (
    <div
      ref={userMenuRef}
      className="absolute bottom-[60px] right-0 bg-[#4C443C] font-bold text-center text-white z-11"
    >
      <div
        onClick={handleAuth}
        className="flex justify-center !p-3 cursor-pointer hover:bg-[#028090] transition-colors delay-100 ease-linear"
      >
        <IconLogout className="w-6 h-6" />
        <p className="block">{isAuthenticated ? 'Log Out' : 'Log In'}</p>
      </div>
      <hr className="bg-[#999] h-[1px] my-[2px]" />
      <div className="flex justify-center !p-3 cursor-pointer hover:bg-[#028090] transition-colors delay-100 ease-linear">
        {user?.email}
      </div>
    </div>
  );
};

export default UserMenu;
