import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetBatchTypesQuery } from '../../../../../../store/services/configApi';
import { ISelectOptions } from 'src/types';
import CustomSelect from 'src/components/CustomSelect';

interface IBatchTypeSelect {
  type?: string;
  isError: boolean;
  errorMessage: string;
  setFieldValue: any;
}

const BatchTypeSelect = ({ type, isError, errorMessage, setFieldValue }: IBatchTypeSelect) => {
  const { t } = useTranslation('batches');
  const { data } = useGetBatchTypesQuery({});

  const typeOptions = data
    ? data.map((type) => ({
        value: type,
        label: t(`batch-type-${type.toLowerCase()}-option`),
      }))
    : [];

  const selectedTypeValue = typeOptions.find((option) => option.value === type);

  const handleChangeType = (option: ISelectOptions) => {
    setFieldValue('type', option?.value);
  };
  return (
    <CustomSelect
      placeholder={t('batch-type-place-holder')}
      value={selectedTypeValue}
      options={typeOptions}
      onSelect={handleChangeType}
      isError={isError}
      errorMessage={errorMessage}
    />
  );
};

export default React.memo(BatchTypeSelect);
