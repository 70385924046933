import React, { useState } from 'react';
import { useLocation } from 'react-router';
import SearchCategories from './components/SearchCategories';
import SearchBatches from './components/SearchBatches';
import ContextMenuModal from '../../components/Modals/ContextMenuModal';
import SearchLocations from './components/SearchLocations';
import SearchForm from '../../components/SearchForm';
import SearchResult from './components/SearchResult';

const Search = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchType = params.get('searchType');
  const searchValue = params.get('searchValue');
  const search = params.get('search');

  const isSearchedValue = !!(searchType && searchValue) || !!search;
  const [isCategoriesAccordionOpen, setIsCategoriesAccordionOpen] =
    useState<boolean>(!isSearchedValue);
  const [isBatchesAccordionOpen, setIsBatchesAccordionOpen] = useState<boolean>(!isSearchedValue);
  const [isLocationsAccordionOpen, setIsLocationsAccordionOpen] =
    useState<boolean>(!isSearchedValue);

  const handleCloseAccordions = () => {
    setIsCategoriesAccordionOpen(false);
    setIsBatchesAccordionOpen(false);
    setIsLocationsAccordionOpen(false);
  };

  return (
    <>
      <SearchForm
        onSubmit={handleCloseAccordions}
        isFilter={!!(searchType && searchValue)}
        searchType={searchType || undefined}
        searchValue={searchValue || undefined}
        defaultValue={search || undefined}
      />
      <SearchCategories
        isOpen={isCategoriesAccordionOpen}
        setIsOpen={setIsCategoriesAccordionOpen}
        handleCloseAccordions={handleCloseAccordions}
      />
      <SearchBatches
        isOpen={isBatchesAccordionOpen}
        setIsOpen={setIsBatchesAccordionOpen}
        handleCloseAccordions={handleCloseAccordions}
      />
      <SearchLocations
        isOpen={isLocationsAccordionOpen}
        setIsOpen={setIsLocationsAccordionOpen}
        handleCloseAccordions={handleCloseAccordions}
      />
      <SearchResult searchType={searchType} searchValue={searchValue} search={search} />
      <ContextMenuModal />
    </>
  );
};

export default React.memo(Search);
