import { createApi, FetchArgs, BaseQueryFn, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ServerError } from '../storeTypes';

export const RTK_TAGS = {
  TAG: 'tag',
  BATCH: 'batch',
  BATCH_BY_ID: 'batch_by_id',
  LOCATION: 'location',
  LOCATION_BY_ID: 'location_by_id',
  EVENTS: 'events',
};

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_PUBLIC_SERVER_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as any).auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
}) as BaseQueryFn<string | FetchArgs, unknown, ServerError, {}>;

export const apiRtk = createApi({
  reducerPath: 'apiRtk',
  baseQuery: baseQuery,
  tagTypes: Object.values(RTK_TAGS),
  endpoints: () => ({}),
});
