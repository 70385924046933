import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetCategoriesQuery } from 'src/store/services/categoryApi';
import i18n from 'i18next';
import { IMultiSelectOptions, IOptions } from 'src/types';
import { handleChangeMultiSelect } from 'src/helpers/handleChangeFormik/handleChangeMultiSelect';
import CustomSelect from 'src/components/CustomSelect';
import { categoriesOptions } from 'src/helpers/categoriesOptions';

interface ICategoriesSelect {
  categories: string[];
  setFieldValue: any;
}

const CategoriesSelect = ({ categories, setFieldValue }: ICategoriesSelect) => {
  const { data, isSuccess } = useGetCategoriesQuery({});
  const [categoryOptions, setCategoryOptions] = useState<IOptions[]>([]);
  const { t } = useTranslation('tag');

  useEffect(() => {
    const loadCategoryOptions = async () => {
      const options = await categoriesOptions({
        categories: data,
        isSuccess: isSuccess,
      });
      setCategoryOptions(options);
    };

    loadCategoryOptions();

    const handleLanguageChange = () => {
      loadCategoryOptions();
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [t, data, isSuccess]);

  const selectedCategoriesValue = categoryOptions.filter(
    (option) => categories?.includes(option.value),
  );

  return (
    <CustomSelect
      placeholder={t('edit_tag_categories_placeholder')}
      value={selectedCategoriesValue}
      options={categoryOptions}
      isSearchable
      isMulti
      onSelect={(option: IMultiSelectOptions) =>
        handleChangeMultiSelect({ option, field: 'categories', setFieldValue })
      }
    />
  );
};

export default React.memo(CategoriesSelect);
