import React, { useRef } from 'react';
import Webcam from 'react-webcam';
import { usePostAssetByIdMutation } from '../store/services/tagsApi';
import { useTranslation } from 'react-i18next';
import { createFormDataFile } from '../helpers/createFormDataFile';

interface IImageScannerProps {
  onClose: () => void;
  addLocalImage?: (img: string) => void;
  assetId: number;
}

const ImageScanner = ({ onClose, addLocalImage, assetId }: IImageScannerProps) => {
  const { t } = useTranslation('tag');
  const [postAssetById] = usePostAssetByIdMutation();

  const webcamRef = useRef<any>(null);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    addLocalImage?.(imageSrc);
    const onLoadFile = (formData: FormData) => {
      postAssetById({ assetId, body: formData });
    };

    createFormDataFile({
      documentType: 'IMAGE',
      imageScannerSrc: imageSrc,
      onLoadFile,
      addLocalImage,
    });
    onClose();
  };

  return (
    <div className="flex flex-col justify-center h-full">
      <div className="flex justify-center">
        <Webcam
          audio={false}
          ref={webcamRef}
          videoConstraints={{ facingMode: 'environment' }}
          screenshotFormat="image/webp"
          className="w-full border border-black"
        />
      </div>
      <div className="flex justify-center items-center w-full h-24  border-2 shadow-sm">
        <button
          type="button"
          onClick={capture}
          className="focus:outline-none text-white bg-primary-color h-12 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
        >
          {t('take_photo')}
        </button>
      </div>
    </div>
  );
};

export default ImageScanner;
