import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

export const useVersionCheck = () => {
  const isMountedRef = useRef(false);
  const location = useLocation();
  useEffect(() => {
    const localStorageVersion = localStorage.getItem('VERSION');
    fetch(`/version.txt?${new Date().getTime()}`)
      .then((res) => res.text())
      .then((version) => {
        if (version !== localStorageVersion) {
          localStorage.setItem('VERSION', version);
          if (!isMountedRef.current) {
            isMountedRef.current = true;
          } else if (localStorageVersion) {
            window.location.reload();
          }
        }
      });
  }, [location.pathname]);
};
