import { apiRtk } from '../index';
import { REQUEST } from '../../storeTypes';
import { BaseQueryMeta } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { IAssetServiceData, IBatchTypesData, IPDFFormatsData, ITagStateData } from './configTypes';

export const configApi = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getPdfFormats: build.query({
      query: () => ({
        url: '/config/pdf',
        method: REQUEST.GET,
      }),
      transformResponse: (response: IPDFFormatsData) => response,
      transformErrorResponse: (error: any, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
    }),
    getAllowedAssetStates: build.query({
      query: () => ({
        url: '/config/asset/states',
        method: REQUEST.GET,
      }),
      transformResponse: (response: ITagStateData) => response,
      transformErrorResponse: (error: any, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
    }),
    getAssetService: build.query({
      query: (params) => ({
        url: '/config/asset/service',
        method: REQUEST.GET,
        params,
      }),
      transformResponse: (response: IAssetServiceData) => response,
      transformErrorResponse: (error: any, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
    }),
    getBatchTypes: build.query({
      query: (params) => ({
        url: '/config/batch/types',
        method: REQUEST.GET,
        params,
      }),
      transformResponse: (response: IBatchTypesData) => response,
      transformErrorResponse: (error: any, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
    }),
  }),
});

export const {
  useGetPdfFormatsQuery,
  useLazyGetAssetServiceQuery,
  useGetAllowedAssetStatesQuery,
  useGetBatchTypesQuery,
} = configApi;
