import React, { Fragment } from 'react';
import { ReactComponent as LoadFileIcon } from 'src/assets/upload_file_filled.svg';
import { ReactComponent as IconLink } from 'src/assets/IconLink.svg';
import { IDocument, IExternalId } from 'src/store/services/tagsApi/tagTypes';
import { ILinkedProperty } from '../../../../../../../../helpers/categoriesOptions';

interface ILoadDocuments {
  tagDocuments: IDocument[] | [];
  linkedProperties: ILinkedProperty[];
  externalIds: IExternalId[];
}

const LoadDocuments = ({ tagDocuments, linkedProperties, externalIds }: ILoadDocuments) => {
  const getFileName = (ulrLink: string) => {
    const regex = /\/([^/?]+)\?/;
    const match = ulrLink.match(regex);

    if (match && match[1]) {
      return match[1];
    } else {
      return 'invalid name';
    }
  };

  return (
    <>
      {tagDocuments?.map((document, index) => {
        const fileName = getFileName(document.url);
        return (
          <a
            href={document.url}
            download
            target="_blank"
            key={`${index}-${document.documentType}`}
            className="flex gap-[10px] "
            rel="noreferrer"
          >
            <span>
              <LoadFileIcon />
            </span>
            <p>{fileName}</p>
          </a>
        );
      })}
      {linkedProperties.map((property, index) => {
        return (
          <Fragment key={`${property}-${index}`}>
            {Object.entries(property).map(([propertyName, propertyLink], index) => (
              <a
                key={`${propertyLink}-${index}`}
                href={propertyLink}
                target="_blank"
                rel="noreferrer"
                className="flex gap-[10px]"
              >
                <span>
                  <IconLink />
                </span>
                <p>{propertyName}</p>
              </a>
            ))}
          </Fragment>
        );
      })}
      {externalIds.map((externalId, index) => {
        const externalIdUrl = externalId.urlStringFormat.substring(
          0,
          externalId.urlStringFormat.length - 4,
        );
        const externalLink = `${externalIdUrl}${externalId.id}`;
        return (
          <a
            href={externalLink}
            download
            target="_blank"
            key={`${index}-${externalLink}`}
            className="flex gap-[10px] "
            rel="noreferrer"
          >
            <span>
              <IconLink />
            </span>
            <p>{externalLink}</p>
          </a>
        );
      })}
    </>
  );
};

export default LoadDocuments;