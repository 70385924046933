import sirkAppLogo from 'src/assets/footer-menu/images/sirk-app-logo.png';
import chairs from 'src/assets/footer-menu/images/landing_chairs_2.png';
import dppLogo from 'src/assets/footer-menu/images/dpp-logo.png';
import dppLanding from 'src/assets/footer-menu/images/landing-dpp.png';

export const themeConfigs: Record<string, Record<string, string>> = {
  defaultTheme: {
    '--font-family': "'Mukta Mahee', 'sans-serif'",
    '--font-color': '76 68 60',
    // '--font-size': '1.5em',
    '--background-color': 'white',
    '--landing-logo-image': `url(${sirkAppLogo})`,
    '--landing-chairs-image': `url(${chairs})`,
    '--bottom-menu-background-color': '#4C443C',
    bottomMenuForegroundColor: 'white',
    bottomMenuActiveColor: '#56ac23',
    '--context-menu-background-color': '#5F9548',
    '--context-menu-foreground-color': 'white',
    '--homepage-processes-border-color': '#CFD24B',
    '--location-badge': '#555',
    '--location-badge-foreground-color': 'white',
    '--category-badge': '#7EB456',
    '--category-badge-foreground-color': 'black',
    '--batch-badge': '#028090',
    '--batch-badge-foreground-color': 'black',
    '--state-badge': '#999',
    '--state-badge-foreground-color': 'black',
    '--scan-qr-button-color': '#2d7734',
    landingCompanyDescription: 'company-description-default-theme',
    landingCompanyDescriptionLink: 'https://reinventar.no',
  },
  dpp: {
    '--font-family': "'serif'",
    '--font-color': '90 90 90',
    // '--font-size': '1.5em',
    '--background-color': 'white',
    '--landing-logo-image': `url(${dppLogo})`,
    '--landing-chairs-image': `url(${dppLanding})`,
    '--bottom-menu-background-color': '#4C443C',
    bottomMenuForegroundColor: 'white',
    bottomMenuActiveColor: '#028090',
    '--context-menu-background-color': '#5F9548',
    '--context-menu-foreground-color': 'white',
    '--homepage-processes-border-color': '#028090',
    '--category-badge': 'red',
    '--category-badge-foreground-color': 'black',
    '--batch-badge': 'green',
    '--batch-badge-foreground-color': 'white',
    '--location-badge': 'white  ',
    '--location-badge-foreground-color': 'blue',
    '--state-badge': '120 120 120',
    '--state-badge-foreground-color': '255 255 255',
    '--scan-qr-button-color': '#028090',
    '--search-field-color': '#028090',
    landingCompanyDescription: 'company-description-dpp-theme',
    landingCompanyDescriptionLink: 'https://www.sirktek.com',
  },
};
