import React from 'react';

interface IModalTitle {
  title: string;
}

const ModalTitle = ({ title }: IModalTitle) => {
  return <div className="flex justify-center font-mukta-mahee text-4xl pt-14">{title}</div>;
};

export default ModalTitle;
