import React, { useEffect, useState } from 'react';
import ErrorField from './ErrorField';

export interface IFormField extends React.InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean;
  errorMessage?: string;
  as?: 'input' | 'textarea'; // Add this to determine whether to render input or textarea
}

const FormField = ({ isError, errorMessage, placeholder, as = 'input', ...rest }: IFormField) => {
  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsFocused(false);
    setHasValue(e.target.value !== '');
  };

  useEffect(() => {
    if (rest?.value) {
      setIsFocused(false);
      setHasValue(true);
    }
  }, [rest?.value]);

  return (
    <div className="relative mt-[15px]">
      {as === 'textarea' ? (
        <textarea
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={`block w-full border px-2 py-2 rounded resize-none ${
            isError ? 'text-red-600 border-red-600' : 'border-gray-300'
          } focus:outline-none`}
          {...(rest as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
          style={{ height: '160px' }}
        />
      ) : (
        <input
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={`block w-full border px-2 py-2 rounded ${
            isError ? 'text-red-600 border-red-600' : 'border-gray-300'
          } focus:outline-none`}
          {...(rest as React.InputHTMLAttributes<HTMLInputElement>)}
        />
      )}
      <label
        className={`absolute left-[10px] top-[9px] transition-all duration-100 ease-linear pointer-events-none text-place-holder ${
          isFocused || hasValue ? '!-top-4 text-xs' : 'text-base'
        }`}
      >
        {placeholder}
      </label>
      <ErrorField errorMessage={errorMessage} />
    </div>
  );
};

export default FormField;
