export const dataURLtoFile = (dataurl: string, filename: string) => {
  let arr = dataurl.split(',');
  let mimeMatch = arr[0].match(/:(.*?);/);

  if (!mimeMatch) {
    console.error('Mime type could not be extracted from the data URL.');
    return '';
  }

  let mime = mimeMatch[1];
  let bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};
