import {
  batchType,
  PatchBatchAssetsBody,
  PatchBatchBodyData,
} from 'src/store/services/batchApi/batchTypes';
import { ITagStates } from '../../../../../store/services/configApi/configTypes';
import { ILocation } from '../../../../../store/services/locationApi/locationTypes';

export interface IBatchValues {
  name: string;
  description: string;
  numberOfNewAssets?: number;
  type?: batchType;
  organization: string;
  assetIds?: number[];
  assetState: ITagStates | '';
  location: ILocation | null;
  categories: string[] | null;
}

export const getBatchInitialValues = (
  data?: PatchBatchBodyData | PatchBatchAssetsBody,
  skipOrganization?: boolean,
): IBatchValues => ({
  name: data?.name || '',
  description: data?.description || '',
  type: data?.type,
  numberOfNewAssets: data?.numberOfNewAssets || 680,
  assetState: '',
  assetIds: [],
  location: data?.location || null,
  organization: !skipOrganization ? data?.owner.name || '' : '',
  categories: [],
});
