import SectionItem, { ISectionItem } from './components/SectionItem';

interface ISectionList {
  items: ISectionItem[];
}

const SectionList = ({ items }: ISectionList) => {
  return (
    <ul className="text-center mx-[30px]">
      {items.map(({ link, text }, index) => (
        <SectionItem key={`${text}-${index}`} link={link} text={text} />
      ))}
    </ul>
  );
};

export default SectionList;
