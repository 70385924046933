import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export const LocationValidation = (omitOrganization: boolean) => {
  const { t } = useTranslation('location');
  return yup.object().shape({
    name: yup.string().required(t('required-location-name')),
    streetAddress: yup.string().required(t('required-location-address')),
    postalCode: yup.string().required(t('required-location-postal-code')),
    city: yup.string().required(t('required-location-city')),
    country: yup.string().required(t('required-location-country')),
    ...(omitOrganization
      ? { organization: yup.string() }
      : { organization: yup.string().required(t('required-location-organization')) }),
  });
};
