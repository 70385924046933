export interface ISectionItem {
  link?: string;
  text: string;
}

const SectionItem = ({ link, text }: ISectionItem) => {
  return (
    <li className="mr-[10px] last:mr-0 p-1 py-[2px]">
      {link ? (
        <a href={link} className="text-[#888]">
          {text}
        </a>
      ) : (
        <p>{text}</p>
      )}
    </li>
  );
};

export default SectionItem;
