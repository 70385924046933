const internalCompaniesMapping = {
  't.sirk.app': true,
};

export const externalCompaniesMapping = {
  'qrc.loopfront.com': 'loopfront',
};

export const companiesMapping = {
  ...internalCompaniesMapping,
  ...externalCompaniesMapping,
};
