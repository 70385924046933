import { useCallback, useState } from 'react';
import { IMultiSelectOptions } from '../../../../../../types';
import { ITagData } from '../../../../../../store/services/tagsApi/tagTypes';
import { handleChangeMultiSelect } from '../../../../../../helpers/handleChangeFormik/handleChangeMultiSelect';
import CustomSelect from '../../../../../CustomSelect';
import ScanButton from '../../../../../../views/Home/components/ScanButton';

interface IExternalIdsMultiselect {
  tagData: ITagData | undefined;
  setFieldValue: any;
}

const ExternalIdsMultiselect = ({
  tagData,
  setFieldValue,
}: IExternalIdsMultiselect) => {
  const [isScannerOpen, setIsScannerOpen] = useState<boolean>(false);
  const filteredExternalIdOptions = tagData?.externalIds.map((externalId) => ({
    label: externalId.id,
    value: externalId,
  }));
  const [selectedExternalIds, setSelectedExternalIds] = useState<IMultiSelectOptions>(
    filteredExternalIdOptions || [],
  );

  const externalIdOptions =
    tagData?.externalIds.map((externalId) => ({
      label: externalId.id,
      value: externalId,
    })) || [];

  const handleScannerToggle = useCallback(() => setIsScannerOpen((prevState) => !prevState), []);

  const handleSelectChange = useCallback(
    (options: IMultiSelectOptions) => {
      handleChangeMultiSelect({ option: options, field: 'externalIds', setFieldValue });
      setSelectedExternalIds(options);
      setTimeout(() => {
        const element = document.getElementById('horizontal-scroll-container');
        element?.children[element?.children?.length - 1]?.scrollIntoView();
      });
    },
    [setFieldValue],
  );

  return (
    <div className="flex items-center gap-3">
      <ScanButton
        isOpen={isScannerOpen}
        handleScannerOpen={handleScannerToggle}
        handleScannerClose={handleScannerToggle}
        scannerMode="assetExternal"
        selectedExternalIds={selectedExternalIds}
        setFieldValue={handleSelectChange}
      />
      <CustomSelect
        placeholder="Select external ids"
        value={selectedExternalIds}
        options={externalIdOptions}
        isMulti
        isSearchable
        onSelect={handleSelectChange}
        hasHorizontalScroll
        customStyle="w-full"
      />
    </div>
  );
};

export default ExternalIdsMultiselect;
