import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCreateBatchMutation } from 'src/store/services/batchApi';
import { PatchBatchBodyData } from 'src/store/services/batchApi/batchTypes';
import BatchForm, {IFormType} from './components/BatchForm';

interface IAddBatchModal {
  onClose: () => void;
  isOpened: boolean;
  data?: PatchBatchBodyData;
  creationType?: IFormType;
}

const AddBatchModal = ({
  data,
  isOpened,
  onClose,
  creationType = 'fromNewObjects',
}: IAddBatchModal) => {
  const { t } = useTranslation('batches');
  const history = useHistory();

  const [createBatch, { data: createBatchData, isSuccess, isLoading, isError, error }] =
    useCreateBatchMutation();

  useEffect(() => {
    if (isSuccess && createBatchData) {
      history.push(`/batch/${createBatchData.id}`);
    }
  }, [isSuccess, isLoading, createBatchData, history]);

  return (
    <BatchForm
      title={t('create-batch')}
      onSubmit={createBatch}
      isSuccess={isSuccess}
      isLoading={isLoading}
      isError={isError}
      error={error}
      data={data}
      isOpened={isOpened}
      onClose={onClose}
      formType={creationType}
    />
  );
};

export default AddBatchModal;
