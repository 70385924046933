import React from 'react';
import { ReactComponent as IconDownload } from '../../assets/IconDownload.svg';
import DownloadBatch from './components/DownloadBatch';
import DownloadLocation from './components/DownloadLocation';
import DownloadFiltered from './components/DownloadFiltered';

export type IDownloadDocumentButton = {
  type: 'batch' | 'location' | 'filtered';
  id: string;
  params?: any;
};

const DownloadDocumentButton = ({ type, id, params }: IDownloadDocumentButton) => {
  return (
    <div className="flex items-center justify-center font-monospace font-bold text-load-button p-[5px] m-[30px] mx-auto w-1/2 text-[14px] border-8 border-solid border-load-button rounded-[30px]">
      <div className="flex items-center justify-between w-[100px] h-[52px]">
        <IconDownload className="w-6 h-6 fill-load-button" />
        {type === 'batch' ? (
          <DownloadBatch batchId={id} />
        ) : type === 'location' ? (
          <DownloadLocation locationId={id} />
        ) : (
          <DownloadFiltered params={params} id={id} />
        )}
      </div>
    </div>
  );
};

export default DownloadDocumentButton;
