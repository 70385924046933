import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CustomSelect from 'src/components/CustomSelect';
import { ISelectOptions } from 'src/types';
import { useAppSelector } from 'src/helpers/reduxCommon';
import { JwtDecodeUsers } from '../helpers/JwtDecodeUsers';

interface IOrganizationsSelect {
  isError?: boolean;
  errorMessage?: any;
  value?: string;
  setFieldValue: any;
  isClearable?: boolean;
}

const OrganizationsSelect = ({
  isError,
  errorMessage,
  value,
  setFieldValue,
  isClearable,
}: IOrganizationsSelect) => {
  const { t } = useTranslation('common');
  const token = useAppSelector((state) => state.auth.token);
  const organizationsOptions = useMemo(() => JwtDecodeUsers(token), [token]);

  const isDisableCompaniesSelect = organizationsOptions.length === 1;

  const handleChangeSelect = (option: ISelectOptions) => {
    setFieldValue('organization', option?.value);
  };

  useEffect(() => {
    if (isDisableCompaniesSelect) {
      setFieldValue('organization', organizationsOptions[0].value);
    }
  }, [isDisableCompaniesSelect, organizationsOptions, setFieldValue]);

  const selectValue = organizationsOptions.find((item) => item.value === value);

  return (
    <CustomSelect<ISelectOptions>
      isClearable={isClearable}
      isDisabled={isDisableCompaniesSelect}
      options={organizationsOptions}
      onSelect={handleChangeSelect}
      placeholder={t('select-organization-placeholder')}
      isError={isError}
      errorMessage={errorMessage}
      value={selectValue}
    />
  );
};

export default React.memo(OrganizationsSelect);
