import { apiRtk, RTK_TAGS } from '../index';
import { BaseQueryMeta } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { REQUEST } from '../../storeTypes';
import {
  ILocation,
  INewCreatedLocationBody,
  IEditLocationBody,
  ILocationData,
} from './locationTypes';

export const locationApi = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getLocation: build.query({
      query: (params) => ({
        url: '/location',
        method: REQUEST.GET,
        params,
      }),
      transformResponse: (response: ILocation[]) => response,
      transformErrorResponse: (error, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
      providesTags: () => [{ type: RTK_TAGS.LOCATION }],
    }),
    getLocationById: build.query({
      query: (id) => ({
        url: `/location/${id}`,
        method: REQUEST.GET,
      }),
      transformResponse: (response: ILocationData) => response,
      transformErrorResponse: (error, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
      providesTags: () => [{ type: RTK_TAGS.LOCATION_BY_ID }],
    }),
    createLocation: build.mutation({
      query: ({ body, headers }: INewCreatedLocationBody) => ({
        url: '/location',
        method: REQUEST.POST,
        body,
        ...(headers ? { headers } : {}),
      }),
      invalidatesTags: () => [{ type: RTK_TAGS.LOCATION }, { type: RTK_TAGS.EVENTS }],
    }),
    editLocation: build.mutation({
      query: ({ locationId, body, headers }: IEditLocationBody) => ({
        url: `/location/${locationId}`,
        method: REQUEST.PATCH,
        body,
        headers,
      }),
      invalidatesTags: () => [{ type: RTK_TAGS.LOCATION_BY_ID }, { type: RTK_TAGS.EVENTS }],
    }),
    deleteLocation: build.mutation({
      query: (locationId) => ({
        url: `/location/${locationId}`,
        method: REQUEST.DELETE,
      }),
      transformErrorResponse: (error, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
      invalidatesTags: () => [{ type: RTK_TAGS.LOCATION }, { type: RTK_TAGS.EVENTS }],
    }),
  }),
});

export const {
  useGetLocationQuery,
  useGetLocationByIdQuery,
  useCreateLocationMutation,
  useEditLocationMutation,
  useDeleteLocationMutation,
  useLazyGetLocationQuery,
} = locationApi;
