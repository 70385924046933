import React from 'react';
import { useHistory } from 'react-router';
import { IBatchData } from '../../../../../store/services/batchApi/batchTypes';

interface IBatchesBadge {
  batches: IBatchData[];
}

const BatchesBadge = ({ batches }: IBatchesBadge) => {
  const history = useHistory();

  const handleBatchClick = (batchId: number) => {
    history.push(`/batch/${batchId}`);
  };

  return (
    <div className="flex flex-wrap gap-2">
      {!!batches?.length &&
        batches.map((batch, index) => (
          <button
            type="button"
            onClick={() => handleBatchClick(batch.id)}
            className="block py-1 px-2 my-2 mx-0.5
            bg-batch-badge
            transition-colors duration-300 ease-in-out
            text-xs text-batch-badge-foreground-color text-center
            rounded-xl
            cursor-pointer"
            key={`${index}batch`}
          >
            {batch.name}
          </button>
        ))}
    </div>
  );
};

export default React.memo(BatchesBadge);
