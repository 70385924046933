import { ServerError, ServerViolation } from '../store/storeTypes';
import React from 'react';

export interface IServerFormError {
  title: string;
  requestError: ServerError;
  isError: boolean;
}

const ServerFormError = ({ title, requestError, isError }: IServerFormError) => {
  const isConstraintViolation = requestError?.status === 400;
  return isError && isConstraintViolation ? (
    <div className="text-error text-sm mt-2">
      <p>{title}</p>
      {(requestError as ServerError)?.data.violations.map(
        (violation: ServerViolation, index: number) => {
          return <p key={`${violation}-${index}`}>{violation.message}</p>;
        },
      )}
    </div>
  ) : isError ? (
    <div className="text-error text-sm mt-2">
      <p>{title}</p>
      <p>{requestError?.data}</p>
    </div>
  ) : null;
};

export default ServerFormError;
