import React from 'react';
import SerialTagSelect from './SerialTagSelect';
import SaveButton from '../../../../../SaveButton';
import { ISelectOptions } from '../../../../../../types';
import { ITagData } from '../../../../../../store/services/tagsApi/tagTypes';

interface ISerialAssetsMenu {
  values: any;
  onSubmit: () => void;
  selectedTag: ISelectOptions;
  setSelectedTag: React.Dispatch<React.SetStateAction<ISelectOptions>>;
  setScannedTagData: React.Dispatch<React.SetStateAction<ITagData | null>>;
}

const SerialAssetsMenu = ({
  values,
  onSubmit,
  selectedTag,
  setSelectedTag,
  setScannedTagData,
}: ISerialAssetsMenu) => {
  return (
    <div className="absolute bottom-[10px] left-0 w-full z-[70]">
      <p className="inline-block w-full text-center bg-[#aaa6a5] p-[3.2px]">
        Serial edit of assets. Scan assets to edit one after another.
      </p>
      <div className="w-full bg-[#837d7d]">
        <SerialTagSelect
          values={values}
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
          setScannedTagData={setScannedTagData}
        />
        <SaveButton submitFunc={onSubmit} disabled={!values.assetId} />
      </div>
    </div>
  );
};

export default SerialAssetsMenu;
