import React from 'react';
import { IDocument } from '../../../../../../../../store/services/tagsApi/tagTypes';
import SwiperGallery from './components/SwiperGallery';

interface IImageUploaderProps {
  assetImages: IDocument[];
}

const ImageGallery = ({ assetImages }: IImageUploaderProps) => {
  return (
    <div className="w-full h-full px-8">
      <SwiperGallery slides={assetImages} />
    </div>
  );
};

export default React.memo(ImageGallery);
