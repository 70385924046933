import { apiRtk, RTK_TAGS } from '../index';
import { REQUEST } from '../../storeTypes';
import { BaseQueryMeta } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { IEvent } from './eventsTypes';

export const eventsApi = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getEvents: build.query({
      query: (params) => ({
        url: `/event`,
        params,
        method: REQUEST.GET,
      }),
      transformResponse: (response: IEvent[], meta: BaseQueryMeta<any>) => {
        return {
          events: response,
          xPageTotal: meta.response.headers.get('X-Page-Total'),
        };
      },
      transformErrorResponse: (error, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
        return error;
      },
      providesTags: () => [{ type: RTK_TAGS.EVENTS }],
    }),
  }),
});

export const { useGetEventsQuery } = eventsApi;
