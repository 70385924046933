import i18n from 'i18next';
import { IOptions } from 'src/types';

export const getActionOptions = async (): Promise<IOptions[]> => {
  await i18n.loadNamespaces('events');
  return [
    { value: '', label: i18n.t('select-select-option', { ns: 'events' }) },
    { value: 'CREATE', label: i18n.t('select-create-option', { ns: 'events' }) },
    { value: 'UPDATE', label: i18n.t('select-update-option', { ns: 'events' }) },
    { value: 'DELETE', label: i18n.t('select-delete-option', { ns: 'events' }) },
  ];
};
