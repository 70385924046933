import React from 'react';

interface IContextItemProps {
  icon: React.FunctionComponent<any>;
  text: string;
  deleteMode?: boolean;
  onClick: () => void;
}

export type IContextItem = IContextItemProps;

const ContextItem = ({ icon: Icon, text, onClick, deleteMode }: IContextItemProps) => {
  const baseStyles =
    'flex !p-[6px] !pl-5 !pr-[30px] cursor-pointer font-mukta-mahee text-context-menu-foreground-color hover:bg-[#028090] transition-colors delay-100 ease-linear';
  const deleteModeStyles =
    'flex !p-[6px] !pl-5 !pr-[30px] cursor-pointer font-mukta-mahee text-context-menu-foreground-color hover:bg-[#cc0000] transition-colors delay-100 ease-linear';
  return (
    <div onClick={onClick} className={deleteMode ? deleteModeStyles : baseStyles}>
      <Icon className="w-6 h-6 fill-context-menu-foreground-color" />
      <p>{text}</p>
    </div>
  );
};

export default ContextItem;
