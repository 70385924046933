import React from 'react';
import AssetTableRow from './components/AssetTableRow';
import { IAssetData } from '../../store/services/assetsApi/assetsTypes';
import { Pagination } from 'flowbite-react';

interface IAssetsTable {
  data: IAssetData;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const AssetsTable = ({ data, currentPage, setCurrentPage }: IAssetsTable) => {
  return (
    <>
      <table className="w-full border-collapse !mt-0 text-[14px]">
        <tbody>
          {data.assets.map((asset) => (
            <AssetTableRow key={asset.id} asset={asset} />
          ))}
        </tbody>
      </table>
      {data?.xPageTotal > 1 && (
        <div className="flex overflow-x-auto justify-center mt-4">
          <Pagination
            currentPage={currentPage}
            totalPages={data?.xPageTotal}
            onPageChange={setCurrentPage}
          />
        </div>
      )}
    </>
  );
};

export default AssetsTable;
