import SectionList from './SectionList';
import { useTranslation } from 'react-i18next';

const SystemSection = () => {
  const { t } = useTranslation('landing');
  const systemItems = [
    { text: `${t('footer-item-version')} ${process.env.REACT_APP_VERSION}` },
    { link: 'https://status.sirktek.com', text: t('footer-item-status') },
    {
      link: 'https://test-api.sirktek.com/assets/v1/q/swagger-ui',
      text: t('footer-item-documentation'),
    },
  ];

  return (
    <div className="mb-[10px]">
      <h3 className="text-center text-[14.04px] font-bold pb-[5px]">{t('footer-title-system')}</h3>
      <SectionList items={systemItems} />
    </div>
  );
};

export default SystemSection;
