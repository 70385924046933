import React, { useState } from 'react';
import { useGetLocationByIdQuery } from 'src/store/services/locationApi';
import { useParams } from 'react-router';
import { useGetAssetQuery } from 'src/store/services/assetsApi';
import { useTranslation } from 'react-i18next';
import Loading from 'src/components/Loading';
import DownloadDocumentButton from 'src/components/DownloadDocumentButton';
import EventsTable from '../Events/components/EventsTable';
import AssetsTable from '../../components/AssetsTable';
import ContextMenuModal from '../../components/Modals/ContextMenuModal';
import CustomAccordion from '../../components/CustomAccordion';

const LocationSingle = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(window.innerWidth >= 768);
  const [isLocationAccordionOpen, setIsLocationAccordionOpen] = useState<boolean>(
    window.innerWidth >= 768,
  );
  const pageSize = 10;
  const params = useParams<{ locationId: string }>();
  const { data: locationData, isLoading: isLocationLoading } = useGetLocationByIdQuery(
    params.locationId,
  );
  const { data: locationAssetsData, isLoading: isLocationAssetsLoading } = useGetAssetQuery({
    pageSize,
    pageOffset: currentPage,
    locationId: params.locationId,
  });

  if (isLocationLoading || isLocationAssetsLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="font-mukta-mahee text-grey-asset">
        <h1 className="font-bold text-[32px] my-[22px]">{locationData?.name}</h1>
        <p className="my-4">{locationData?.description}</p>
        <ul>
          <li>{locationData?.address.streetAddress}</li>
          <li>
            {locationData?.address.postalCode} {locationData?.address.city}
          </li>
          <li>{locationData?.address.country}</li>
        </ul>
        {!!locationAssetsData?.assets.length && (
          <CustomAccordion
            isAccordionOpen={isAccordionOpen}
            setIsAccordionOpen={setIsAccordionOpen}
            title="Assets"
          >
            <AssetsTable
              data={locationAssetsData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            <DownloadDocumentButton type="location" id={params.locationId} />
          </CustomAccordion>
        )}
      </div>
      <CustomAccordion
        title={t('latest-events-title', { ns: 'batches' })}
        isAccordionOpen={isLocationAccordionOpen}
        setIsAccordionOpen={setIsLocationAccordionOpen}
      >
        <EventsTable defaultId={params.locationId} defaultType={'LOCATION'} pageSize={5} />
      </CustomAccordion>
      <ContextMenuModal modalType="location" editLocationData={locationData} />
    </>
  );
};

export default React.memo(LocationSingle);
