import { useEffect, useRef } from 'react';

export default function useEventListener<T extends Event>(
  eventType: string,
  callback: (event: T) => void,
  element: Window | HTMLElement | Document = window,
): void {
  const callbackRef = useRef<(event: T) => void>(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (element == null) return;
    const handler = (event: Event) => callbackRef.current(event as T);
    element.addEventListener(eventType, handler);

    return () => element.removeEventListener(eventType, handler);
  }, [eventType, element]);
}
