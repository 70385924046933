import React from 'react';
import { useTranslation } from 'react-i18next';

interface ICategoryRow {
  propertyName: string;
  propertyValue?: string;
  classNames?: string;
}

const CategoryRow = ({ propertyName, propertyValue, classNames }: ICategoryRow) => {
  const { t } = useTranslation('tag');
  const hasLink = propertyValue
    ? propertyValue.startsWith('http://') || propertyValue.startsWith('https://')
    : false;
  const extractedLink =
    propertyValue && propertyValue.indexOf('/getfile') >= 0
      ? propertyValue.substring(0, propertyValue.indexOf('/getfile'))
      : propertyValue;

  return (
    <tr
      className={`font-['Mukta_Mahee',_Arial,_sans-serif] cursor-pointer even:bg-[#f9f9f9] ${classNames}`}
    >
      <td className="border border-solid border-[#ddd] text-base p-[6px] text-left relative">
        {t(`table-property-${propertyName}`, { defaultValue: propertyName })}
      </td>
      {propertyValue ? (
        <td className="border border-solid border-[#ddd] text-base p-[6px] text-left relative">
          {!hasLink ? (
            propertyValue
          ) : (
            <a href={propertyValue} target="_blank" rel="noreferrer">
              {extractedLink}
            </a>
          )}
        </td>
      ) : (
        <td className="border border-solid border-[#ddd] text-base p-[6px] text-center relative">
          - - - -
        </td>
      )}
    </tr>
  );
};

export default CategoryRow;
