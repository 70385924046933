import React from 'react';
import { ReactComponent as IconQR } from '../../../assets/home/IconQR.svg';
import { IMultiSelectOptions } from '../../../types';
import ModalContainer from '../../../components/Modals/components/ModalContainer';
import useOpenScanner from 'src/hooks/useOpenScanner';
import Scanner, { IScannerMode } from '../../../components/Scanner/Scanner';

interface IScanButtonProps {
  isOpen: boolean;
  handleScannerOpen: () => void;
  handleScannerClose: () => void;
  scannerMode?: IScannerMode;
  selectedAssets?: IMultiSelectOptions;
  selectedExternalIds?: IMultiSelectOptions;
  setFieldValue?: any;
  scanBtnClassName?: string;
  iconClassName?: string;
}

const ScanButton = ({
  isOpen,
  handleScannerOpen,
  handleScannerClose,
  scannerMode,
  selectedAssets,
  selectedExternalIds,
  setFieldValue,
  iconClassName,
  scanBtnClassName,
}: IScanButtonProps) => {
  useOpenScanner({ openScanner: handleScannerOpen });

  return (
    <>
      <div
        onClick={handleScannerOpen}
        data-modal-show="default-modal"
        className={`h-[50px] w-[50px] m-[10px] p-[1px] bg-white rounded-md inline-block transition-colors duration-300 ease-in-out cursor-pointer ${scanBtnClassName}`}
      >
        <IconQR className={`w-12 h-12 fill-scan-qr-btn-color ${iconClassName}`} />
      </div>

      <ModalContainer isModalOpened={isOpen} onClose={handleScannerClose}>
        <Scanner
          scannerMode={scannerMode}
          setFieldValue={setFieldValue}
          onClose={handleScannerClose}
          selectedAssets={selectedAssets}
          selectedExternalIds={selectedExternalIds}
        />
      </ModalContainer>
    </>
  );
};

export default React.memo(ScanButton);
