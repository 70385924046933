import React from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as SaveIcon } from '../../src/assets/IconSave.svg';

interface ISaveButton {
  submitFunc: () => void;
  disabled?: boolean;
}

const SaveButton = ({ submitFunc, disabled = false }: ISaveButton) => {
  return ReactDOM.createPortal(
    <button
      onClick={submitFunc}
      disabled={disabled}
      className="fixed z-[100] bottom-[70px] right-5 flex items-center justify-center w-[50px] h-[50px] rounded-[50%] bg-green-tag cursor-pointer disabled:bg-[#aaa6a5] disabled:cursor-default"
    >
      <SaveIcon className="w-[30px] h-[30px]  fill-white " />
    </button>,
    document.getElementById('modal') as Element,
  );
};

export default SaveButton;
