import i18n from 'i18next';
import { IOptions } from 'src/types';

export const getBatchStateOptions = async (): Promise<IOptions[]> => {
  await i18n.loadNamespaces('batches');

  return [
    { value: 'PLANNED', label: i18n.t('batch-state-planned-option', { ns: 'batches' }) },
    { value: 'IN_PROGRESS', label: i18n.t('batch-state-in_progress-option', { ns: 'batches' }) },
    { value: 'COMPLETED', label: i18n.t('batch-state-completed-option', { ns: 'batches' }) },
  ];
};