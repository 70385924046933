import store from './index';

export enum REQUEST {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export interface ServerError {
  data?: any;
  status?: any;
  message?: string;
  error?: any;
}

export interface ServerViolation {
  field: string;
  message: string;
}

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
