import React, { useEffect, useState } from 'react';
import { useGetLocationQuery } from '../../store/services/locationApi';
import Loading from '../../components/Loading';
import ScanButton from '../Home/components/ScanButton';
import LocationsTable from './components/LocationsTable/index';
import LocationsFilter from './components/LocationsTable/components/LocationsFilter';
import { ILocation } from '../../store/services/locationApi/locationTypes';
import ContextMenuModal from '../../components/Modals/ContextMenuModal';

const Location = () => {
  const { data, isLoading } = useGetLocationQuery({});
  const [filteredLocations, setFilteredLocations] = useState<ILocation[] | undefined>(undefined);

  useEffect(() => {
    if (!isLoading) {
      setFilteredLocations(data);
    }
  }, [isLoading, data]);

  const [isScannerOpened, setIsScannerOpened] = useState<boolean>(false);
  const handleOpenScanner = () => setIsScannerOpened(true);
  const handleCloseScanner = () => setIsScannerOpened(false);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <ScanButton
          isOpen={isScannerOpened}
          handleScannerOpen={handleOpenScanner}
          handleScannerClose={handleCloseScanner}
          scannerMode="location"
        />
        <LocationsFilter data={data} setLocations={setFilteredLocations} />
      </div>
      {filteredLocations && <LocationsTable data={filteredLocations} />}
      <ContextMenuModal />
    </>
  );
};

export default React.memo(Location);
