import React from 'react';

interface IAssetTagBadge {
  tag: string;
}

const AssetTagBadge = ({ tag }: IAssetTagBadge) => {
  return (
    <span className="font-monospace text-base text-tag border border-solid !border-tag rounded px-[15px] py-[5px]">
      {tag}
    </span>
  );
};

export default AssetTagBadge;
