import { jwtDecode, JwtPayload } from 'jwt-decode';

interface ISearchableOrganizations {
  [key: string]: string[];
}

export const JwtDecodeUsers = (token: string | null, key?: string) => {
  const searchableKey = key || 'https://sirk.app/organizations';

  if (token) {
    const decodingJwtToken = jwtDecode<JwtPayload & ISearchableOrganizations>(token);
    const isCompaniesExist = Object.keys(decodingJwtToken).includes(searchableKey);

    return isCompaniesExist
      ? [
          ...decodingJwtToken[searchableKey].map((item) => ({
            label: item,
            value: item,
          })),
        ]
      : [];
  }
  return [];
};
