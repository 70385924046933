import { useTranslation } from 'react-i18next';
import {useEffect, useState} from 'react';
import { getCategoriesTypeOptions } from '../../../../../../../helpers/categoriesOptions';
import { dateToLocalFormat } from '../../../../../../../helpers/dateToLocalFormat';
import { IMultiSelectOptions, IOptions, ISelectOptions } from '../../../../../../../types';
import {FormikErrors} from "formik";
import {IEditAssetBodyData} from "../../../../../../../store/services/assetsApi/assetsTypes";
import CustomSelect from '../../../../../../CustomSelect';
import CustomDatepicker from '../../../../../../CustomDatepicker';
import FormField from '../../../../../../FormField';

interface ICategorySelectProps {
  item: string;
  data: any;
  categoryProperties: any;
  values: any;
  setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<IEditAssetBodyData>>;
}

const SelectSwitcher = ({
  item,
  data,
  categoryProperties,
  values,
  setFieldValue,
}: ICategorySelectProps) => {
  const { t, i18n } = useTranslation('tag');
  const [categoryOptions, setCategoryOptions] = useState<IOptions[]>([]);

  useEffect(() => {
    const getCategoryOptions = async () => {
      const options = await getCategoriesTypeOptions({
        categories: data,
        categoryType: item,
      });
      setCategoryOptions(options);
    };

    if (data) {
      getCategoryOptions();
    }

    i18n.on('languageChanged', getCategoryOptions);

    return () => {
      i18n.off('languageChanged', getCategoryOptions);
    };
  }, [data, item, i18n]);

  const handleSelectOption = (option: ISelectOptions) => {
    setFieldValue(`properties`, { ...values.properties, [item]: option?.value });
  };

  const handleMultiselectOption = (options: IMultiSelectOptions) => {
    setFieldValue(`properties`, {
      ...values.properties,
      [item]: options.map((option) => option.value).join(','),
    });
  };

  const handleSelectDate = (date: Date | undefined) => {
    setFieldValue(`properties`, {
      ...values.properties,
      [item]: dateToLocalFormat(date),
    });
  };

  switch (categoryProperties[item]) {
    case 'CATEGORY':
      return (
        <CustomSelect
          key={`${item}-select`}
          placeholder={`${t(`table-property-${item}`, { defaultValue: item })}Category`}
          value={{
            value: values.properties?.[item],
            label: values.properties?.[item],
          }}
          onSelect={handleSelectOption}
          options={categoryOptions}
        />
      );
    case 'MULTI_CATEGORY':
      return (
        <CustomSelect
          key={`${item}-select`}
          placeholder={t(`table-property-${item}`, { defaultValue: item })}
          value={values.properties?.[item]?.split(',').map((category: string) => ({
            label: category,
            value: category,
          }))}
          isMulti
          onSelect={handleMultiselectOption}
          options={categoryOptions}
        />
      );
    case 'DATE':
      return (
        <CustomDatepicker
          key={`${item}-date`}
          placeholder={t(`table-property-${item}`, { defaultValue: item })}
          value={values.properties?.[item] && new Date(values.properties?.[item])}
          showClearButton
          onSelectedDateChanged={handleSelectDate}
          language={i18n.language}
        />
      );
    default:
      return (
        <FormField
          key={`${item}-field`}
          type={'text'}
          value={values.properties?.[item]}
          name={`${item}`}
          placeholder={t(`table-property-${item}`, { defaultValue: item })}
          onChange={(e: any) =>
            setFieldValue(`properties`, {
              ...values.properties,
              [item]: e.target.value,
            })
          }
        />
      );
  }
};

export default SelectSwitcher;