import React from 'react';
import { useHistory } from 'react-router';
import { ILocation } from '../../../../../store/services/locationApi/locationTypes';

interface ILocalesBadge {
  local: ILocation | null;
}

const LocalesBadge = ({ local }: ILocalesBadge) => {
  const history = useHistory();

  const handleBatchClick = (locationId: number) => {
    history.push(`/location/${locationId}`);
  };
  return local?.id ? (
    <div className="flex flex-wrap gap-2">
      <button
        type="button"
        onClick={() => handleBatchClick(local?.id)}
        className="block py-1 px-2 my-2 mx-0.5
            bg-location-badge
            transition-colors duration-300 ease-in-out
            text-xs text-location-badge-foreground-color text-center
            rounded-xl
            cursor-pointer"
      >
        {local.name}
      </button>
    </div>
  ) : (
    <></>
  );
};

export default React.memo(LocalesBadge);
