import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateAssetMutation } from 'src/store/services/assetsApi';
import TagForm from './components/TagForm';

interface IAddTagModal {
  isOpened: boolean;
  onClose: () => void;
}

const AddTagModal = ({ isOpened, onClose }: IAddTagModal) => {
  const { t } = useTranslation('tag');
  const [createAsset, { isSuccess, isLoading, isError, error }] = useCreateAssetMutation();

  return (
    <TagForm
      title={t('create-asset')}
      onSubmit={createAsset}
      isSuccess={isSuccess}
      isLoading={isLoading}
      isError={isError}
      error={error}
      isOpened={isOpened}
      onClose={onClose}
    />
  );
};

export default AddTagModal;
