import React from 'react';

interface IErrorField {
  errorMessage?: string;
}

const ErrorField = ({ errorMessage }: IErrorField) => {
  return (
    <p
      className={`h-[16px] text-error text-xs px-[0.7rem]  leading-none ${
        errorMessage ? 'visible' : 'invisible'
      }`}
    >
      {errorMessage ?? ''}
    </p>
  );
};

export default ErrorField;
