import { FormikErrors } from 'formik';
import { IMultiSelectOptions, IOptions } from 'src/types';

interface IHandleChangeMultiSelect {
  option: IMultiSelectOptions;
  field: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<any>>;
}

export const handleChangeMultiSelect = ({
  option,
  field,
  setFieldValue,
}: IHandleChangeMultiSelect) => {
  const updatedOptions = option.map((item: IOptions) => item.value);
  setFieldValue(field, updatedOptions);
};
