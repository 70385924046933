import { ILocationData } from 'src/store/services/locationApi/locationTypes';

export interface ILocationValues {
  name: string;
  description: string;
  streetAddress: string;
  postalCode: string;
  city: string;
  country: string;
  organization: string;
}

export const getLocationInitialValues = (data?: ILocationData): ILocationValues => ({
  name: data?.name || '',
  description: data?.description || '',
  streetAddress: data?.address.streetAddress || '',
  postalCode: data?.address.postalCode || '',
  country: data?.address.country || '',
  city: data?.address.city || '',
  organization: data?.owner.name || '',
});
