import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'flowbite-react';

import { IExternalId } from 'src/store/services/tagsApi/tagTypes';
import LoopFrontLogo from '../../../../../assets/logo/Loopfront-logo.png';

interface IExternalLinksProps {
  externalIds?: IExternalId[];
}

const externalsLinksMapping = {
  loopfront: LoopFrontLogo,
};

const ExternalLinks = ({ externalIds }: IExternalLinksProps) => {
  return (
    <div className={'w-full max-h-52 gap-2'}>
      {!!externalIds?.length &&
        externalIds.map((externalLink) => {
          const tagUrl = externalLink?.urlStringFormat.replace(/{id}/g, externalLink?.id);
          const asset =
            externalsLinksMapping[externalLink?.systemName as keyof typeof externalsLinksMapping];

          return (
            <Link
              key={`link-${externalLink?.id}`}
              target="_blank"
              to={{ pathname: tagUrl || '' }}
              rel="noopener noreferrer"
            >
              {asset ? (
                <img
                  src={asset || ''}
                  alt={externalLink?.systemName}
                  className="w-[150px] object-contain mt-4"
                />
              ) : (
                <span>{externalLink?.systemName}</span>
              )}
            </Link>
          );
        })}
    </div>
  );
};

export default React.memo(ExternalLinks);
