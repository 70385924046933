import SectionList from './SectionList';
import { useTranslation } from 'react-i18next';

const HelpSection = () => {
  const { t } = useTranslation('landing');
  const helpItems = [
    { link: 'https://support.sirktek.com', text: t('footer-item-support') },
    { link: 'https://reinventar.no', text: 'RE:inventar' },
    {
      link: 'https://www.sirktek.com',
      text: 'SIRK:tek',
    },
  ];
  return (
    <div className="mb-[10px]">
      <h3 className="text-center text-[14.04px] font-bold pb-[5px]">{t('footer-title-help')}</h3>
      <SectionList items={helpItems} />
    </div>
  );
};

export default HelpSection;
