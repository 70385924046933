import { useState, useEffect } from 'react';
import { useSwiper } from 'swiper/react';
import { ReactComponent as IconArrow } from 'src/assets/arrow_forward.svg';

const SwiperNavigation = () => {
  const swiper = useSwiper();
  const [isFirstSlide, setIsFirstSlide] = useState(swiper.isBeginning);
  const [isLastSlide, setIsLastSlide] = useState(swiper.isEnd);

  useEffect(() => {
    const updateNavigationState = () => {
      setIsFirstSlide(swiper.isBeginning);
      setIsLastSlide(swiper.isEnd);
    };

    swiper.on('slideChange', updateNavigationState);

    return () => {
      swiper.off('slideChange', updateNavigationState);
    };
  }, [swiper]);

  const navigateToPrev = () => {
    swiper.slidePrev();
  };

  const navigateToNext = () => {
    swiper.slideNext();
  };

  return (
    <>
      <button
        onClick={navigateToPrev}
        disabled={isFirstSlide}
        style={{ opacity: isFirstSlide ? 0.5 : 1 }}
        className="fixed top-1/2 -translate-y-1/2 -left-9 lg:-left-14 z-10"
      >
        <IconArrow className="w-11 h-11 lg:w-16 lg:h-16 rotate-180 fill-green-tag" />
      </button>
      <button
        onClick={navigateToNext}
        disabled={isLastSlide}
        style={{ opacity: isLastSlide ? 0.5 : 1 }}
        className="fixed top-1/2 -translate-y-1/2 -right-9 lg:-right-14 text-green-tag z-10"
      >
        <IconArrow className="w-11 h-11 lg:w-16 lg:h-16 fill-green-tag" />
      </button>
    </>
  );
};

export default SwiperNavigation;
