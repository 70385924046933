import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ILocation } from '../../../../../../store/services/locationApi/locationTypes';
import { IBatchData } from '../../../../../../store/services/batchApi/batchTypes';
import {
  getCategoryMapping,
  getCategoryProperties,
} from '../../../../../../helpers/categoriesOptions';
import CategoryBadge from './components/CategoryBadge';
import CategoriesTable from './components/CategoriesTable';
import { ICategories } from '../../../../../../store/services/categoryApi/categoryTypes';
import { IExternalId } from '../../../../../../store/services/tagsApi/tagTypes';
import AuthenticatedTagInfo from '../AuthenticatedTagInfo';
import ExternalLinks from '../ExternalLinks';

interface ITagDetails {
  properties: Record<string, string>;
  selectedCategories?: string[];
  connectedCategories: string[];
  location?: ILocation;
  batches?: IBatchData[];
  state: ITagStates;
  categoryList?: ICategories;
  externalIds?: IExternalId[];
  isCategoriesSuccess: boolean;
  isAuthenticated: boolean;
}

export type ITagStates =
  | 'PROVISIONED'
  | 'COMMISSIONED'
  | 'STORED'
  | 'SERVICE'
  | 'TRANSPORT'
  | 'DISCARDED';

const TagDetails = ({
  properties,
  selectedCategories,
  connectedCategories,
  location,
  batches,
  isAuthenticated,
  categoryList,
  externalIds,
  isCategoriesSuccess,
  state,
}: ITagDetails) => {
  const { t } = useTranslation('tag');
  const history = useHistory();
  const modelCategories = Object.entries(categoryList?.categoryTypes?.model?.instances || {}).map(
    (category) => category[0],
  );
  const manufacturerCategories = Object.entries(
    categoryList?.categoryTypes?.manufacturer?.instances || {},
  ).map((category) => category[0]);

  const categoryMapping = useMemo(
    () =>
      selectedCategories?.length && isCategoriesSuccess
        ? getCategoryMapping(selectedCategories, properties, categoryList?.categoryTypes)
        : [],
    [categoryList, selectedCategories, isCategoriesSuccess, properties],
  );

  const filteredConnectedCategories =
    connectedCategories?.filter(
      (connectedCategory) =>
        !categoryMapping.some(
          (selectedCategory) => selectedCategory.category === connectedCategory,
        ),
    ) || [];

  const handleCategoryClick = (category: string) => {
    history.push(`/search?searchType=category&searchValue=${category}`);
  };

  return (
    <div className="flex flex-wrap items-baseline">
      {filteredConnectedCategories?.map((connectedCategory, index) => {
        const categoryProperties = getCategoryProperties(
          connectedCategory,
          properties,
          categoryList?.categoryTypes,
        );

        return !modelCategories.includes(connectedCategory) ? (
          <CategoryBadge
            key={`${connectedCategory}-${index}`}
            category={connectedCategory}
            handleCategoryClick={() => handleCategoryClick(connectedCategory)}
            categoryProperties={categoryProperties}
            withProperties={false}
          />
        ) : null;
      })}

      {categoryMapping.map(({ category, categoryProperties }, index) => {
        return modelCategories.includes(category) || manufacturerCategories.includes(category) ? (
          <CategoryBadge
            key={`${category}-${index}`}
            category={category}
            handleCategoryClick={() => handleCategoryClick(category)}
            categoryProperties={categoryProperties}
            badgeType="selected"
            withProperties={false}
          />
        ) : null;
      })}

      {categoryMapping.map(({ category, categoryProperties }, index) => {
        return !modelCategories.includes(category) && !manufacturerCategories.includes(category) ? (
          <CategoryBadge
            key={`${category}-${index}`}
            badgeType="selected"
            category={category}
            handleCategoryClick={() => handleCategoryClick(category)}
            categoryProperties={categoryProperties}
          />
        ) : null;
      })}

      <AuthenticatedTagInfo
        location={location}
        batches={batches}
        isAuthenticated={isAuthenticated}
      />

      <div className="w-full">
        <h5 className="inline-block text-xs text-state-badge-foreground-color font-mukta-mahee tracking-tight my-1 mx-[2px] py-1 px-2 bg-state-badge rounded-xl">
          {t(`asset-state-${state.toLowerCase()}-option`)}
        </h5>
      </div>

      <ExternalLinks externalIds={externalIds} />

      {categoryMapping.map(({ category, categoryProperties }, index) => {
        return modelCategories.includes(category) || manufacturerCategories.includes(category) ? (
          <CategoriesTable
            key={`${category}-${index}`}
            category={category}
            categoryProperties={categoryProperties}
            rowClassNames='font-tag-font-family text-tag-font-color'
          />
        ) : null;
      })}
      {filteredConnectedCategories.map((category, index) => {
        const categoryProperties = getCategoryProperties(
          category,
          properties,
          categoryList?.categoryTypes,
        );
        return modelCategories.includes(category) || manufacturerCategories.includes(category) ? (
          <CategoriesTable
            key={`${category}-${index}`}
            category={category}
            categoryProperties={categoryProperties}
            rowClassNames='font-tag-font-family text-tag-font-color'
          />
        ) : null;
      })}
    </div>
  );
};

export default React.memo(TagDetails);
