import { useEffect } from 'react';
import { ITagData } from '../store/services/tagsApi/tagTypes';
import { assetCompanyThemeConfigs } from '../constants/assetCompanyThemeConfigs';

const useChangeAssetTheme = (data: ITagData | undefined) => {
  useEffect(() => {
    const themeCategory = [...(data?.categories || []), ...(data?.connectedCategories || [])].find(
      (item) => assetCompanyThemeConfigs[item],
    );

    const sortVariables = (themeConfigs: { [key: string]: string }) => {
      const assetContent = document.getElementById('asset-content') as HTMLDivElement;

      if (assetContent) {
        assetContent.style.fontSize = 'initial';
        assetContent.style.fontFamily = 'initial';
        assetContent.style.color = 'initial';

        Object.entries(themeConfigs).forEach(([key, value]) => {
          assetContent.style.setProperty(key, value);
        });
      }
    };

    if (themeCategory) {
      const themeConfigs =
        assetCompanyThemeConfigs[themeCategory] || assetCompanyThemeConfigs.defaultTheme;
      sortVariables(themeConfigs);
    }

    return () => {
      sortVariables(assetCompanyThemeConfigs.defaultTheme);
    };
  }, [data]);
};

export default useChangeAssetTheme;
