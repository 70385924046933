import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { ReactComponent as IconHome } from 'src/assets/footer-menu/IconHome.svg';
import { ReactComponent as IconLayers } from 'src/assets/footer-menu/IconLayers.svg';
import { ReactComponent as IconLocation } from 'src/assets/footer-menu/IconLocation.svg';
import { ReactComponent as IconSearch } from 'src/assets/footer-menu/IconSearch.svg';
import { ReactComponent as IconPerson } from 'src/assets/footer-menu/IconPerson.svg';
import { ROUTES } from 'src/constants/routes';
import { Link } from 'react-router-dom';
import UserMenu from './components/UserMenu';
import { themeConfigs } from '../../constants/themeConfigs';

const Layout = ({ children }: React.PropsWithChildren<any>) => {
  const { t } = useTranslation('layout');
  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
  const location = useLocation();
  const currentTheme = process.env.REACT_APP_THEME || 'defaultTheme';
  const activeColor = themeConfigs[currentTheme].bottomMenuActiveColor;
  const foregroundColor = themeConfigs[currentTheme].bottomMenuForegroundColor;

  const matchRoute = (route: string, pathname: string) => {
    const routeSegments = route.split('/').filter(Boolean);
    const pathSegments = pathname.split('/').filter(Boolean);

    if (routeSegments.length !== pathSegments.length) return false;

    return routeSegments.every(
      (segment, index) => segment.startsWith(':') || segment === pathSegments[index],
    );
  };

  const isRouteActive = (routes: string[]): boolean => {
    return routes.some((route) => matchRoute(route, location.pathname));
  };

  const handleUserMenuToggle = useCallback(() => {
    setIsUserMenuOpen((prev) => !prev);
  }, []);

  return (
    <div className="relative h-full w-full flex flex-col">
      <div className="p-4 pb-[70px] h-full overflow-y-auto">{children}</div>
      <nav className="fixed bottom-0 left-0 w-full text-white flex justify-around items-center h-[60px] pb-[10px] bg-bottom-menu-bg-color z-[80]">
        <Link
          to={ROUTES.HOME}
          className="flex flex-col items-center justify-center leading-4"
          style={{ color: foregroundColor }}
        >
          <IconHome
            className="w-6 h-6"
            style={{ fill: isRouteActive([ROUTES.HOME]) ? activeColor : foregroundColor }}
          />
          {t('home')}
        </Link>
        <Link
          to={ROUTES.SEARCH}
          className="flex flex-col items-center justify-center leading-4"
          style={{ color: foregroundColor }}
        >
          <IconSearch
            className="w-6 h-6"
            style={{ fill: isRouteActive([ROUTES.SEARCH]) ? activeColor : foregroundColor }}
          />
          {t('bottom-nav-search')}
        </Link>
        <Link
          to={ROUTES.BATCHES}
          className="flex flex-col items-center justify-center leading-4"
          style={{ color: foregroundColor }}
        >
          <IconLayers
            className="w-6 h-6"
            style={{
              fill: isRouteActive([ROUTES.BATCHES, ROUTES.BATCH_SINGLE])
                ? activeColor
                : foregroundColor,
            }}
          />
          {t('batch')}
        </Link>
        <Link
          to={ROUTES.LOCATION}
          className="flex flex-col items-center justify-center leading-4"
          style={{ color: foregroundColor }}
        >
          <IconLocation
            className="w-6 h-6"
            style={{
              fill: isRouteActive([ROUTES.LOCATION, ROUTES.SINGLE_LOCATION])
                ? activeColor
                : foregroundColor,
            }}
          />
          {t('location')}
        </Link>
        <button
          type="button"
          onClick={handleUserMenuToggle}
          className="flex flex-col items-center justify-center leading-4"
          style={{ color: foregroundColor }}
        >
          <IconPerson className="w-6 h-6" style={{ fill: foregroundColor }} />
          {t('profile')}
        </button>
        {isUserMenuOpen && <UserMenu onClose={handleUserMenuToggle} />}
      </nav>
    </div>
  );
};
export default Layout;
