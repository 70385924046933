import React, { useState } from 'react';
import { useGetLocationQuery } from '../../../store/services/locationApi';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import CustomAccordion from '../../../components/CustomAccordion';

interface ISearchLocations {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseAccordions: () => void;
}

const SearchLocations = ({ isOpen, setIsOpen, handleCloseAccordions }: ISearchLocations) => {
  const { t } = useTranslation('search');
  const { data, isSuccess } = useGetLocationQuery({});
  const history = useHistory();
  const [showAll, setShowAll] = useState<boolean>(false);

  const maxInitialDisplay = 9;

  const handleShowAllClick = () => {
    setShowAll(true);
  };

  const sortedData =
    data &&
    [...data].sort((a, b) => {
      return a.address.postalCode.localeCompare(b.address.postalCode);
    });

  const visibleLocations = sortedData?.slice(0, maxInitialDisplay) || [];
  const hiddenLocations = sortedData?.slice(maxInitialDisplay) || [];

  return (
    <CustomAccordion
      title={t('title-locations')}
      isAccordionOpen={isOpen}
      setIsAccordionOpen={setIsOpen}
    >
      {isSuccess && !!sortedData?.length && (
        <div>
          {visibleLocations.map(({ id, name, address }, index) => {
            const postalCode = address.postalCode;
            let isNewLocation = !!index;
            if (index > 0) {
              const prevPostalCode = sortedData[index - 1].address.postalCode;
              isNewLocation = postalCode.charAt(0) !== prevPostalCode.charAt(0);
            }

            const handleCategoryClick = () => {
              history.push(`/search?searchType=locationId&searchValue=${id}`);
              handleCloseAccordions();
            };

            return isNewLocation ? (
              <React.Fragment key={id}>
                <br />
                <p
                  onClick={handleCategoryClick}
                  className="inline-block py-1 px-2 my-2 mx-0.5 bg-location-badge transition-colors duration-300 ease-in-out text-xs text-location-badge-foreground-color text-center rounded-xl cursor-pointer"
                >
                  {name}
                </p>
              </React.Fragment>
            ) : (
              <p
                key={id}
                onClick={handleCategoryClick}
                className="inline-block py-1 px-2 my-2 mx-0.5 bg-location-badge transition-colors duration-300 ease-in-out text-xs text-location-badge-foreground-color text-center rounded-xl cursor-pointer"
              >
                {name}
              </p>
            );
          })}
          {hiddenLocations?.length > 0 && !showAll && (
            <p
              className="inline-block py-1 px-2 my-2 mx-0.5 bg-location-badge transition-colors duration-300 ease-in-out text-xs text-location-badge-foreground-color text-center rounded-xl cursor-pointer"
              onClick={handleShowAllClick}
            >
              {t('search-batch-show-more-btn')} +
            </p>
          )}
          {showAll &&
            hiddenLocations.map(({ id, name, address }, index) => {
              const postalCode = address.postalCode;
              let isNewLocation = !!index;
              if (index > 0) {
                const prevPostalCode = sortedData[index - 1].address.postalCode;
                isNewLocation = postalCode.charAt(0) !== prevPostalCode.charAt(0);
              }

              const handleCategoryClick = () => {
                history.push(`/search?searchType=locationId&searchValue=${id}`);
              };

              return isNewLocation ? (
                <React.Fragment key={id}>
                  <br />
                  <p
                    onClick={handleCategoryClick}
                    className="inline-block py-1 px-2 my-2 mx-0.5 bg-location-badgetransition-colors duration-300 ease-in-out text-xs text-location-badge-foreground-color text-center rounded-xl cursor-pointer"
                  >
                    {name}
                  </p>
                </React.Fragment>
              ) : (
                <p
                  key={id}
                  onClick={handleCategoryClick}
                  className="inline-block py-1 px-2 my-2 mx-0.5 bg-location-badge transition-colors duration-300 ease-in-out text-xs text-location-badge-foreground-color text-center rounded-xl cursor-pointer"
                >
                  {name}
                </p>
              );
            })}
        </div>
      )}
    </CustomAccordion>
  );
};

export default React.memo(SearchLocations);
