import { ITagData } from 'src/store/services/tagsApi/tagTypes';
import { IEditAssetBodyData } from 'src/store/services/assetsApi/assetsTypes';

export const getTagInitialValues = (data?: ITagData): IEditAssetBodyData => ({
  name: data?.name || '',
  description: data?.description || '',
  state: data?.state || '',
  batches: data?.batches || [],
  categories: data?.categories || [],
  location: data?.location || null,
  organization: data?.owner.name || '',
  properties: data?.properties || {},
  externalIds: data?.externalIds || [],
  assetId: data?.id || null,
});
