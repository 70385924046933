import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllowedAssetStatesQuery } from '../../../../../../store/services/configApi';
import { ISelectOptions } from 'src/types';
import { handleChangeSelect } from 'src/helpers/handleChangeFormik/handleChangeSelect';
import CustomSelect from 'src/components/CustomSelect';

interface ITagStateSelect {
  state: string;
  setFieldValue: any;
  field?: string;
}

const TagStateSelect = ({ state, setFieldValue, field = 'state' }: ITagStateSelect) => {
  const { t } = useTranslation('tag');
  const { data: tagStatesData } = useGetAllowedAssetStatesQuery({});

  const stateOptions = tagStatesData?.map((state) => ({
    value: state,
    label: t(`asset-state-${state.toLowerCase()}-option`),
  }));

  const selectedStateValue = stateOptions?.find((option) => option.value === state);

  return (
    <CustomSelect
      isClearable
      placeholder={t('edit_tag_state_placeholder')}
      value={selectedStateValue}
      options={stateOptions || []}
      onSelect={(option: ISelectOptions) => handleChangeSelect({ option, field, setFieldValue })}
    />
  );
};

export default React.memo(TagStateSelect);
