import PrivacySection from './components/PrivacySection';
import SystemSection from './components/SystemSection';
import HelpSection from './components/HelpSection';

const Index = () => {
  return (
    <footer className="flex justify-center items-center flex-wrap py-5 px-0 font-[_Arial,_sans-serif] text-[#888] text-[12px] bg-white shadow-none min-h-0 text-left">
      <PrivacySection />
      <SystemSection />
      <HelpSection />
    </footer>
  );
};

export default Index;
