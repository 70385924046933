import React, { useState } from 'react';
import { IBatchData } from '../../../../store/services/batchApi/batchTypes';
import BatchRow from './components/BatchRow';
import { Pagination } from 'flowbite-react';

interface IFilteredBatches {
  data: IBatchData[];
  pageSize?: number;
}

const BatchesTable = ({ data, pageSize = 15 }: IFilteredBatches) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentData = data.slice(startIndex, endIndex);

  return (
    <>
      <table className="w-full border-collapse text-sm leading-normal !mt-5">
        <tbody>
          {currentData.map((batch: IBatchData, index: number) => (
            <BatchRow key={index} data={batch} />
          ))}
        </tbody>
      </table>
      {totalPages > 1 && (
        <div className="flex overflow-x-auto justify-center pb-16 md:pb-2">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
            previousLabel="&#60;&#60;"
            nextLabel="&#62;&#62;"
          />
        </div>
      )}
    </>
  );
};

export default BatchesTable;
