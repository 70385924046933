import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IMultiSelectOptions } from 'src/types';
import { handleChangeMultiSelect } from 'src/helpers/handleChangeFormik/handleChangeMultiSelect';
import CustomSelect from 'src/components/CustomSelect';
import { useGetBatchesQuery } from 'src/store/services/batchApi';
import { IBatchData } from 'src/store/services/batchApi/batchTypes';

interface IBatchesSelect {
  batches: IBatchData[];
  setFieldValue: any;
}

const BatchesSelect = ({ batches, setFieldValue }: IBatchesSelect) => {
  const { data, isSuccess } = useGetBatchesQuery({});

  const { t } = useTranslation('tag');
  const batchOptions = useMemo(
    () => (isSuccess ? data.map((batch) => ({ value: batch, label: batch.name })) : []),
    [data, isSuccess],
  );
  const selectedBatchesValue = batchOptions.filter(
    (option) => batches?.find((batch) => batch.id === option.value.id),
  );
  return (
    <CustomSelect
      placeholder={t('edit_tag_batches_placeholder')}
      value={selectedBatchesValue}
      options={batchOptions}
      isMulti
      onSelect={(option: IMultiSelectOptions) =>
        handleChangeMultiSelect({ option, field: 'batches', setFieldValue })
      }
    />
  );
};

export default React.memo(BatchesSelect);
