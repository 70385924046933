import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ISelectOptions } from 'src/types';
import CustomSelect from 'src/components/CustomSelect';
import { handleChangeSelect } from 'src/helpers/handleChangeFormik/handleChangeSelect';
import { useGetLocationQuery } from 'src/store/services/locationApi';
import { ILocation } from 'src/store/services/locationApi/locationTypes';

interface ILocationSelect {
  location?: ILocation | null;
  setFieldValue: any;
}

const LocationSelect = ({ location, setFieldValue }: ILocationSelect) => {
  const { t } = useTranslation('tag');
  const { data, isSuccess } = useGetLocationQuery({});

  const locationOptions = useMemo(
    () => (isSuccess ? data?.map((location) => ({ value: location, label: location.name })) : []),
    [data, isSuccess],
  );
  const selectedLocationValue = locationOptions.find((option) => option.value.id === location?.id);
  return (
    <CustomSelect
      isClearable
      placeholder={t('edit_tag_location_placeholder')}
      value={selectedLocationValue}
      options={locationOptions}
      onSelect={(option: ISelectOptions) =>
        handleChangeSelect({ option, field: 'location', setFieldValue })
      }
    />
  );
};

export default React.memo(LocationSelect);
