import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import ErrorField from './ErrorField';
import { ReactComponent as IconSearch } from 'src/assets/home/IconSearch.svg';
import SearchedBadge from '../views/Search/components/SearchResult/components/SearchedBadge';

interface ISearchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  handleSubmit?: (ev: any) => void;
  wrapperClassName?: string;
  inputClassName?: string;
  isError?: boolean;
  errorMessage?: string;
  isSearched?: boolean;
  searchedType?: string;
  searchedText?: string;
  onSearchedBadgeClick?: () => void;
}

const SearchField = ({
  handleSubmit,
  placeholder,
  wrapperClassName,
  inputClassName,
  isError,
  errorMessage,
  isSearched,
  searchedType,
  searchedText,
  onSearchedBadgeClick,
  ...rest
}: ISearchProps) => {
  const { t } = useTranslation('layout');
  !placeholder ? (placeholder = t('search')) : null;
  const searchedBadgeRef = useRef<HTMLDivElement>(null);
  const [badgeWidth, setBadgeWidth] = useState(0); // Стан для зберігання ширини

  useEffect(() => {
    if (searchedBadgeRef.current) {
      setBadgeWidth(searchedBadgeRef.current.offsetWidth);
    }
  }, [searchedText, isSearched]);

  return (
    <div
      className={`relative bg-white p-[10px] m-0 rounded-[4px] flex-1 shadow-[0_2px_5px_rgba(0,0,0,0.1)] border-[1px] border-solid border-search-field-color ${wrapperClassName}`}
    >
      <label className="flex items-center gap-1">
        {isSearched && searchedText && (
          <SearchedBadge
            text={searchedText}
            onRemove={onSearchedBadgeClick}
            passedRef={searchedBadgeRef}
            className={
              searchedType === 'batchId'
                ? '!bg-batch-badge'
                : searchedType === 'locationId'
                  ? '!bg-location-badge text-white'
                  : '!bg-category-badge'
            }
          />
        )}
        <input
          type="text"
          placeholder={placeholder}
          style={{ width: isSearched ? `calc(80% - ${badgeWidth}px)` : '100%' }}
          className={`h-full border-none p-0 pr-4 outline-none focus:[box-shadow:none] text-base ${inputClassName}`}
          {...rest}
        />
      </label>
      <button
        onClick={handleSubmit}
        className="group absolute top-0 right-0 flex justify-center items-center w-9 h-full rounded-r-none border-transparent focus:border-transparent focus:ring-0"
      >
        <IconSearch className="w-6 h-6 fill-tag group-hover:fill-[#5F9548]" />
      </button>
      {isError && (
        <div className="absolute -bottom-5 left-0">
          <ErrorField errorMessage={errorMessage} />
        </div>
      )}
    </div>
  );
};

export default React.memo(SearchField);
