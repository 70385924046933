import React, { useRef } from 'react';
import ContextItem, { IContextItem } from './components/ContextItem';
import useClickOutside from '../../../../hooks/useClickOutSide/useClickOutside';
import { useAuth0 } from '@auth0/auth0-react';

interface IContextMenuProps {
  onClose: () => void;
  items: IContextItem[];
}

const ContextMenu = ({ onClose, items }: IContextMenuProps) => {
  const contextMenuRef = useRef<HTMLDivElement>(null);
  const { isAuthenticated } = useAuth0();

  useClickOutside(contextMenuRef, onClose);

  return (
    <div
      ref={contextMenuRef}
      className={`fixed ${
        isAuthenticated ? 'bottom-[70px]' : 'bottom-5'
      } pr-6 right-5 bg-[#5F9548] font-bold rounded-[4px_4px_25px_4px] z-[70]`}
    >
      {items.map(({ icon, text, onClick, deleteMode }, index) => {
        const handleItemClick = () => {
          onClick();
          onClose();
        };
        return (
          <ContextItem
            key={index}
            icon={icon}
            text={text}
            onClick={handleItemClick}
            deleteMode={deleteMode}
          />
        );
      })}
    </div>
  );
};

export default ContextMenu;
