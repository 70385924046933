import React, { useCallback, useState } from 'react';
import { ISelectOptions } from '../../../../../../types';
import { ITagData } from '../../../../../../store/services/tagsApi/tagTypes';
import { ReactComponent as IconCamera } from 'src/assets/footer-menu/IconCamera.svg';
import ScanButton from '../../../../../../views/Home/components/ScanButton';
import CustomSelect from '../../../../../CustomSelect';
import ModalContainer from '../../../../components/ModalContainer';
import ImageScanner from '../../../../../ImageScanner';

interface ISerialTagSelect {
  values: any;
  selectedTag: ISelectOptions;
  setSelectedTag: React.Dispatch<React.SetStateAction<ISelectOptions>>;
  setScannedTagData: React.Dispatch<React.SetStateAction<ITagData | null>>;
}

const SerialTagSelect = ({
  values,
  selectedTag,
  setSelectedTag,
  setScannedTagData,
}: ISerialTagSelect) => {
  const [isScannerOpen, setIsScannerOpen] = useState<boolean>(false);
  const [isImageScannerOpen, setIsImageScannerOpen] = useState<boolean>(false);

  const handleSelectChange = useCallback(
    (option: ISelectOptions, data: ITagData) => {
      setScannedTagData(data);
      setSelectedTag(option);
    },
    [setSelectedTag, setScannedTagData],
  );

  const handleScannerToggle = useCallback(() => setIsScannerOpen((prevState) => !prevState), []);
  const handleImageScannerToggle = useCallback(
    () => setIsImageScannerOpen((prevState) => !prevState),
    [],
  );

  return (
    <div className="flex items-center gap-3">
      <ScanButton
        isOpen={isScannerOpen}
        handleScannerOpen={handleScannerToggle}
        handleScannerClose={handleScannerToggle}
        scannerMode="serialAssets"
        setFieldValue={handleSelectChange}
        scanBtnClassName="!bg-[#837d7d]"
        iconClassName="fill-white"
      />
      <CustomSelect
        placeholder="Scan tags"
        value={selectedTag}
        options={[]}
        onSelect={handleSelectChange as any}
        menuIsOpen={false}
        disableFocus
        isDisabled
        isChip
        customStyle="w-32"
      />
      <button
        onClick={handleImageScannerToggle}
        disabled={!values.assetId}
        className="group flex gap-1 text-white whitespace-nowrap disabled:text-[#aaa6a5] disabled:cursor-default"
      >
        <IconCamera className="group-disabled:fill-[#aaa6a5] fill-white" />
        Take photo
      </button>
      <ModalContainer
        isModalOpened={isImageScannerOpen}
        onClose={handleImageScannerToggle}
        className="z-[70]"
      >
        <ImageScanner onClose={handleImageScannerToggle} assetId={values.assetId} />
      </ModalContainer>
    </div>
  );
};

export default SerialTagSelect;
