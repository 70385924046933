import { useState } from 'react';
import { useLazyGetAssetFileByLocationIdQuery } from '../../../store/services/assetsApi';
import useDownloadFile from '../../../hooks/useDownloadFile';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import SelectPDFFormat from './SelectPDFFormat';
import Loading from '../../Loading';

interface IDownloadLocationProps {
  locationId: string;
}

const DownloadLocation = ({ locationId }: IDownloadLocationProps) => {
  const [isPDFFormatSelectionModalOpen, setIsPDFFormatSelectionModalOpen] =
    useState<boolean>(false);
  const [selectedPDFFormat, setSelectedPDFFormat] = useState<string | null>(null);

  const [
    triggerLocationPDF,
    { data: pdfLocationData, isLoading: isPdfDataLoading, isFetching: isPdfFetching },
  ] = useLazyGetAssetFileByLocationIdQuery();
  const [
    triggerLocationCSV,
    { data: csvLocationData, isLoading: isCsvDataLoading, isFetching: isCsvFetching },
  ] = useLazyGetAssetFileByLocationIdQuery();

  const downloadPdfRef = useDownloadFile({
    fileData: pdfLocationData,
    isFileLoading: isPdfDataLoading,
    fileId: locationId,
    fileType: 'pdf',
  });
  const downloadCsvRef = useDownloadFile({
    fileData: csvLocationData,
    isFileLoading: isCsvDataLoading,
    fileId: locationId,
    fileType: 'csv',
  });

  const handleClickLoadPDF = () => {
    if (!downloadPdfRef?.current?.href) {
      triggerLocationPDF({
        id: locationId,
        headers: { Accept: 'application/pdf' },
        params: selectedPDFFormat ? { format: selectedPDFFormat } : null,
      });
    }
  };
  const handleClickLoadCSV = () => {
    if (!downloadCsvRef?.current?.href) {
      triggerLocationCSV({
        id: locationId,
        headers: { Accept: 'text/csv' },
      });
    }
  };

  const handleTogglePDFFormatSelectionModal = () => {
    setIsPDFFormatSelectionModalOpen((prevState) => !prevState);
  };

  if (isPdfFetching || isCsvFetching) {
    return <Loading />;
  }

  return (
    <>
      <a
        className="cursor-pointer"
        onClick={handleTogglePDFFormatSelectionModal}
        ref={downloadPdfRef}
      >
        PDF
      </a>
      <span>|</span>
      <a className="cursor-pointer" onClick={handleClickLoadCSV} ref={downloadCsvRef}>
        CSV
      </a>
      <ConfirmationModal
        isOpen={isPDFFormatSelectionModalOpen}
        onAccept={handleClickLoadPDF}
        onCancel={handleTogglePDFFormatSelectionModal}
        onClose={handleTogglePDFFormatSelectionModal}
        confirmationText="Choose PDF format"
        confirmationAcceptText="Download PDF"
        confirmationCancelText="Cancel"
      >
        <SelectPDFFormat setSelectedPDFFormat={setSelectedPDFFormat} />
      </ConfirmationModal>
    </>
  );
};

export default DownloadLocation;
