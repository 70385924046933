import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { IBatchEditMode, IFormType } from './index';

export const BatchValidation = (isEdit?: IBatchEditMode, formType?: IFormType) => {
  const { t } = useTranslation('batches');
  return yup.object().shape({
    name: yup.string().required(t('required-new-batch-name')),
    ...(isEdit
      ? {}
      : {
          numberOfNewAssets: yup
            .number()
            .typeError(t('required-batch-type-number'))
            .required(t('required-batch-number')),
        }),
    description: yup.string(),
    organization:
      isEdit !== 'editBatchAssets'
        ? yup.string().required(t('required-batch-organization'))
        : yup.string(),
    ...(formType === 'fromExistingObjects'
      ? { type: yup.string().required(t('required-batch-type')) }
      : {}),
  });
};
