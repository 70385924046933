import { useEffect, useRef } from 'react';

interface IUseDownloadFile {
  fileData: string | undefined;
  isFileLoading: boolean;
  fileId: string;
  fileType: 'pdf' | 'csv';
}

const useDownloadFile = ({ fileData, isFileLoading, fileId, fileType }: IUseDownloadFile) => {
  const downloadRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (fileData && !isFileLoading) {
      if (downloadRef.current) {
        downloadRef.current.href = fileData;
        downloadRef.current.download = `${fileType}-${fileId}.${fileType}`;
        downloadRef.current.click();

        downloadRef.current.removeAttribute('href');
      }
    }
  }, [fileData, isFileLoading, fileId, fileType]);

  return downloadRef;
};

export default useDownloadFile;
