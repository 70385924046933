import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EventsTable from '../../../Events/components/EventsTable';
import CustomAccordion from '../../../../components/CustomAccordion';

const Latest = () => {
  const { t } = useTranslation('layout');
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(window.innerWidth >= 768);

  return (
    <CustomAccordion
      title={t('latest-changes')}
      isAccordionOpen={isAccordionOpen}
      setIsAccordionOpen={setIsAccordionOpen}
    >
      <EventsTable pageSize={10} />
    </CustomAccordion>
  );
};

export default Latest;
