import { ISelectOptions } from 'src/types';
import { FormikHelpers } from 'formik/dist/types';

interface IHandleChangeSelect<T> {
  option: ISelectOptions;
  field: string;
  setFieldValue: FormikHelpers<T>['setFieldValue'];
}

export const handleChangeSelect = <T>({ option, field, setFieldValue }: IHandleChangeSelect<T>) => {
  setFieldValue(field, option?.value);
};
