import React from 'react';
import { dataURLtoFile } from './dataURLtoFile';
interface ICreateFormDataFile {
  documentType: 'DOCUMENT' | 'IMAGE';
  onLoadFile: (formData: FormData) => void;
  event?: React.ChangeEvent<HTMLInputElement>;
  isPublic?: boolean;
  addLocalImage?: (img: string) => void;
  imageScannerSrc?: string;
}
export const createFormDataFile = ({
  documentType,
  event,
  addLocalImage,
  isPublic = true,
  onLoadFile,
  imageScannerSrc,
}: ICreateFormDataFile) => {
  const createAndAppendFormData = (fileOrDataURL: File | string, fileName: string) => {
    const formData = new FormData();
    const file =
      typeof fileOrDataURL === 'string' ? dataURLtoFile(fileOrDataURL, fileName) : fileOrDataURL;
    formData.append('file', file);
    formData.append('fileName', fileName);
    formData.append('isPublic', String(isPublic));
    formData.append('documentType', documentType);
    onLoadFile(formData);
  };
  const processFileInput = (file: File) => {
    const fileReader = new FileReader();
    const fileName = documentType === 'DOCUMENT' ? file.name : `${new Date().toISOString()}.png`;
    fileReader.onloadend = () => {
      if (fileReader.result) {
        createAndAppendFormData(fileReader.result as string, fileName);
        if (addLocalImage) addLocalImage(fileReader.result as string);
      }
    };
    fileReader.readAsDataURL(file);
  };
  if (event?.target.files?.[0]) {
    processFileInput(event.target.files[0]);
  } else if (imageScannerSrc) {
    const fileName = `${new Date().toISOString()}.png`;
    createAndAppendFormData(imageScannerSrc, fileName);
  }
};
