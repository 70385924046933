import AssetTagBadge from '../../../../../components/AssetTagBadge';
import QRCode from 'react-qr-code';

interface ITagQRCode {
  tag: string;
}

const TagQRCode = ({ tag }: ITagQRCode) => {
  return (
    <div className="flex justify-center flex-col gap-3 w-1/2 px-3">
      <QRCode value={`https://t.sirk.app/${tag}`} className="w-16 h-16 mx-5" />
      <div className="max-h-[30px]">
        <AssetTagBadge tag={tag} />
      </div>
    </div>
  );
};

export default TagQRCode;
