import { useLazyGetAssetFileQuery } from '../../../store/services/assetsApi';
import useDownloadFile from '../../../hooks/useDownloadFile';
import SelectPDFFormat from './SelectPDFFormat';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import { useState } from 'react';
import Loading from '../../Loading';

interface IDownloadFilteredProps {
  params: any;
  id: string;
}

const DownloadFiltered = ({ params, id }: IDownloadFilteredProps) => {
  const [isPDFFormatSelectionModalOpen, setIsPDFFormatSelectionModalOpen] =
    useState<boolean>(false);
  const [selectedPDFFormat, setSelectedPDFFormat] = useState<string | null>(null);

  const [
    triggerFilteredPDF,
    { data: pdfFilteredData, isLoading: isPdfDataLoading, isFetching: isPdfFetching },
  ] = useLazyGetAssetFileQuery();

  const [
    triggerFilteredCSV,
    { data: csvFilteredData, isLoading: isCsvDataLoading, isFetching: isCsvFetching },
  ] = useLazyGetAssetFileQuery();

  const downloadPdfRef = useDownloadFile({
    fileData: pdfFilteredData,
    isFileLoading: isPdfDataLoading,
    fileId: id,
    fileType: 'pdf',
  });

  const downloadCsvRef = useDownloadFile({
    fileData: csvFilteredData,
    isFileLoading: isCsvDataLoading,
    fileId: id,
    fileType: 'csv',
  });

  const handleClickLoadPDF = () => {
    if (!downloadPdfRef?.current?.href) {
      triggerFilteredPDF({
        params: selectedPDFFormat ? { ...params, format: selectedPDFFormat } : params,
        headers: { Accept: 'application/pdf' },
      });
    }
  };
  const handleClickLoadCSV = () => {
    if (!downloadCsvRef?.current?.href) {
      triggerFilteredCSV({
        params,
        headers: { Accept: 'text/csv' },
      });
    }
  };

  const handleTogglePDFFormatSelectionModal = () => {
    setIsPDFFormatSelectionModalOpen((prevState) => !prevState);
  };

  if (isPdfFetching || isCsvFetching) {
    return <Loading />;
  }

  return (
    <>
      <a
        className="cursor-pointer"
        onClick={handleTogglePDFFormatSelectionModal}
        ref={downloadPdfRef}
      >
        PDF
      </a>
      <span>|</span>
      <a className="cursor-pointer" onClick={handleClickLoadCSV} ref={downloadCsvRef}>
        CSV
      </a>
      <ConfirmationModal
        isOpen={isPDFFormatSelectionModalOpen}
        onAccept={handleClickLoadPDF}
        onCancel={handleTogglePDFFormatSelectionModal}
        onClose={handleTogglePDFFormatSelectionModal}
        confirmationText="Choose PDF format"
        confirmationAcceptText="Download PDF"
        confirmationCancelText="Cancel"
      >
        <SelectPDFFormat setSelectedPDFFormat={setSelectedPDFFormat} />
      </ConfirmationModal>
    </>
  );
};

export default DownloadFiltered;
