import { Route, RouteProps } from 'react-router-dom';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';

const PrivateRoute = (props: RouteProps) => {
  const { isLoading, isAuthenticated } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      history.push('/');
    }
  }, [isLoading, isAuthenticated, history]);

  return !isLoading && isAuthenticated ? <Route {...props} /> : null;
};

export default PrivateRoute;
